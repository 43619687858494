export class MarketingPreference {
  constructor(
    public emailOptIn = false,
    public smsOptIn = false,
    public seenSmsOptIn = false
  ) {}

  static fromDynamic(data: { [key: string]: any }): MarketingPreference {
    return new MarketingPreference(
      data["email_opt_in"] ?? false,
      data["sms_opt_in"] ?? false,
      data["seen_sms_opt_in"] ?? false
    );
  }
}

export class Customer {
  constructor(
    public id: number,
    public marketingPreferences: MarketingPreference = new MarketingPreference(),
    public loyaltyPoints: number,
    public isFirstTimeCustomer: boolean,
    public lastSelectedStoreId: number | null
  ) {}

  static fromDynamic(data: { [key: string]: any }) {
    return new Customer(
      data["id"],
      MarketingPreference.fromDynamic(data["marketing_preferences"]),
      data["loyalty_points"],
      data["is_first_time_customer"],
      data["last_selected_store_id"] ?? null
    );
  }
}
