import { DefaultTheme } from "styled-components"

export interface ThemeProps {
  theme: DefaultTheme
}

export const theme: DefaultTheme = {
  colors: {
    "Background/Primary": "#FFFFFF",
    "Background/Secondary": "#F5F5F5",
    "Background/Brand": "#ACBDAA",
    "Content/Error": "#F2453B",
    "Content/Primary": "#1C1C1C",
    "Content/Secondary": "#555D6A",
    "Content/Tertiary": "#808080",
    "Content/Placeholder": "#99A1AD",
    "Content/Success": "#34C759",
    "Content/Brand": "#000000",
    "Border/Primary": "#E3E0CA",
    "Border/Secondary": "#F0F0F0",
  },
  text: {
    "Body/Small": {
      fontFamily: "TWKLausanne",
      fontSize: "12px",
      fontWeight: "300",
      lineHeight: "16px",
    },
    "Body/Regular": {
      fontFamily: "TWKLausanne",
      fontSize: "14px",
      fontWeight: "300",
      lineHeight: "20px",
    },
    "Body/Medium": {
      fontFamily: "TWKLausanne",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "20px",
    },
    "Body/SemiBold": {
      fontFamily: "TWKLausanne",
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "20px",
    },
    "Body/Bold": {
      fontFamily: "TWKLausanne",
      fontSize: "14px",
      fontWeight: "600",
      lineHeight: "16px",
    },
    "Label/Regular": {
      fontFamily: "TWKLausanne",
      fontSize: "12px",
      fontWeight: "300",
      lineHeight: "12px",
    },
    "LabelMultiline/Regular": {
      fontFamily: "TWKLausanne",
      fontSize: "12px",
      fontWeight: "300",
      lineHeight: "20px",
    },
    "Heading/Medium": {
      fontFamily: "TWKLausanne",
      fontSize: "20px",
      fontWeight: "400",
      lineHeight: "16px",
    },
    "Heading/SemiBold": {
      fontFamily: "TWKLausanne",
      fontSize: "20px",
      fontWeight: "500",
      lineHeight: "16px",
    },
    "Heading/Bold": {
      fontFamily: "TWKLausanne",
      fontSize: "20px",
      fontWeight: "600",
      lineHeight: "16px",
    },
    "Subheading/Regular": {
      fontFamily: "TWKLausanne",
      fontSize: "16px",
      fontWeight: "300",
      lineHeight: "16px",
    },
    "Subheading/Bold": {
      fontFamily: "TWKLausanne",
      fontSize: "16px",
      fontWeight: "600",
      lineHeight: "16px",
    },
    "DisplayHeading/Large": {
      fontFamily: "TWKLausanne",
      fontSize: "26px",
      fontWeight: "500",
      lineHeight: "30px",
    },
    "DisplayHeading/XLarge": {
      fontFamily: "TWKLausanne",
      fontSize: "48px",
      fontWeight: "700",
      lineHeight: "30px",
    },
  },
  borderRadius: "4px",
}
