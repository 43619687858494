export class Credit {
  constructor(
    public amount: number,
    public currency: string,
  ) {}

  static fromDynamic(data: { [key: string]: any }): Credit {
    return new Credit(data["balance"]["value"], data["balance"]["currency"])
  }
}
