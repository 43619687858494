import styled, { css } from "styled-components"

interface Props {
  size: number | "fill"
  $vertical?: boolean
}

export const Spacer = styled.div<Props>`
  display: block;
  ${({ size, $vertical }) => css`
    ${size !== "fill" && `${$vertical ? "width" : "height"}: ${size * 4}px;`}
    ${size === "fill" &&
    `
      margin-${$vertical ? "left" : "top"}: auto;
    `}
    ${$vertical ? "height" : "width"}: 0.1rem;
  `}
  flex-shrink: 0;
  flex-grow: 0;
`
