import styled, { css } from "styled-components"

interface Props {
  $vertical?: boolean
  maxLength?: string
  thickness?: string
}

export const Divider = styled.div<Props>`
  ${({ $vertical, maxLength = "100%", thickness = "1px", theme }) =>
    $vertical
      ? css`
          height: 100%;
          border-right: solid ${thickness} ${theme.colors["Border/Primary"]};
          max-height: ${maxLength};
        `
      : css`
          width: 100%;
          border-top: solid ${thickness} ${theme.colors["Border/Primary"]};
          max-width: ${maxLength};
        `}
`
