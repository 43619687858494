import { PaymentMethod } from "./paymentMethod";

export class CustomerPayment {
  constructor(
    public id: number,
    public type: string,
    public paymentMethod: PaymentMethod | null = null,
    public amount: number,
    public currency: string,
    public status: string
  ) {}

  static fromDynamic(data: { [key: string]: any }): CustomerPayment {
    return new CustomerPayment(
      data["id"],
      data["type"],
      data["payment_method"] != null
        ? PaymentMethod.fromDynamic(data["payment_method"])
        : null,
      data["amount"],
      data["currency"],
      data["status"]
    );
  }
}
