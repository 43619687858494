export class LatLng {
  // The latitude in degrees between -90.0 and 90.0, both inclusive.
  readonly latitude: number;

  // The longitude in degrees between -180.0 (inclusive) and 180.0 (exclusive).
  readonly longitude: number;

  /**
   * Creates a geographical location specified in degrees `latitude` and
   * `longitude`.
   *
   * The latitude is clamped to the inclusive interval from -90.0 to +90.0.
   *
   * The longitude is normalized to the half-open interval from -180.0
   * (inclusive) to +180.0 (exclusive).
   */
  constructor(latitude: number, longitude: number) {
    this.latitude =
      latitude < -90.0 ? -90.0 : latitude > 90.0 ? 90.0 : latitude;
    this.longitude =
      longitude >= -180.0 && longitude < 180.0
        ? longitude
        : ((longitude + 180.0) % 360.0) - 180.0;
  }

  /**
   * Converts this object to something serializable in JSON.
   */
  toJson(): [number, number] {
    return [this.latitude, this.longitude];
  }

  /**
   * Initialize a LatLng from a [lat, lng] array.
   */
  static fromJson(json: unknown): LatLng | null {
    if (!Array.isArray(json) || json.length !== 2) {
      return null;
    }
    const [lat, lng] = json;
    if (typeof lat !== "number" || typeof lng !== "number") {
      return null;
    }
    return new LatLng(lat, lng);
  }

  toString(): string {
    return `LatLng(${this.latitude}, ${this.longitude})`;
  }

  equals(other: unknown): boolean {
    return (
      other instanceof LatLng &&
      other.latitude === this.latitude &&
      other.longitude === this.longitude
    );
  }
}
