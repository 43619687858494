import { map } from "lodash"

import { Customer } from "./customer"
import { AppliedPromo } from "./appliedPromo"
import { MerchantStore } from "./store"
import { OrderItem } from "./orderItem"
import { OrderPickupDetail } from "./orderPickupDetail"
import { OrderDeliveryDetail } from "./orderDeliveryDetail"
import { CustomerPayment } from "./customerPayment"
import {
  RefundReason,
  RefundType,
  matchRefundReason,
  matchRefundType,
} from "./orderItemOption"
import { OrderTotal } from "./orderTotal"
import dayjs, { Dayjs } from "@helpers/dayjs"

export enum OrderState {
  // before submission
  inCart = "inCart",
  cartEmptied = "cartEmptied",

  // after submission
  created = "created",
  processing = "processing",
  submitted = "submitted",
  inPreparation = "inPreparation",
  inTransit = "inTransit",
  delivered = "delivered",
  pickupReady = "pickupReady",
  returnInitialized = "returnInitialized", // return intialized (usually alcohol cases)

  // completed order
  completed = "completed",
  refunded = "refunded",
  canceled = "canceled",
  returned = "returned",

  // error
  unknown = "unknown",
  error = "error",
}

export const ORDER_STATE_MAP: Record<string, OrderState> = {
  IN_CART: OrderState.inCart,
  CART_EMPTIED: OrderState.cartEmptied,
  CREATED: OrderState.created,
  PROCESSING: OrderState.processing,
  SUBMITTED: OrderState.submitted,
  IN_PREPARATION: OrderState.inPreparation,
  IN_TRANSIT: OrderState.inTransit,
  DELIVERED: OrderState.delivered,
  PICKUP_READY: OrderState.pickupReady,
  COMPLETED: OrderState.completed,
  REFUNDED: OrderState.refunded,
  RETURNED: OrderState.returned,
  RETURN_INITIALIZED: OrderState.returnInitialized,
  CANCELED: OrderState.canceled,
  UNKNOWN: OrderState.unknown,
  ERROR: OrderState.error,
}

export const REVERSE_ORDER_STATE = Object.entries(ORDER_STATE_MAP).reduce<
  Record<OrderState, string>
>(
  (acc, [key, value]) => ({
    ...acc,
    [value]: key,
  }),
  {} as Record<OrderState, string>,
)

export enum OrderScheduleType {
  asap = "asap",
  fixedTime = "fixedTime",
}

export enum FulfillmentType {
  pickup = "pickup",
  delivery = "delivery",
}

export enum DriverState {
  unassigned = "unassigned",
  assigned = "assigned",
  enroutePickup = "enroutePickup",
  enrouteDropOff = "enrouteDropOff",
  enrouteReturn = "enrouteReturn",
  deliveryComplete = "deliveryComplete",
}

export const matchOrderState = (state: string) => {
  return ORDER_STATE_MAP[state] ?? OrderState.unknown
}

export const reverseMatchOrderState = (state: OrderState) => {
  return REVERSE_ORDER_STATE[state]
}

export const matchOrderScheduleType = (type: string): OrderScheduleType => {
  switch (type) {
    case "ASAP":
      return OrderScheduleType.asap
    case "FIXED_TIME":
      return OrderScheduleType.fixedTime
    default:
      throw new Error(`invalid OrderScheduleType type: ${type}`)
  }
}

export const matchFulfillmentType = (fulfillment: string) => {
  switch (fulfillment) {
    case "PICKUP":
      return FulfillmentType.pickup
    case "DELIVERY":
      return FulfillmentType.delivery
    default:
      throw new Error(`invalid FulfillmentType type: ${fulfillment}`)
  }
}

export class Order {
  constructor(
    public id: number,
    public store: MerchantStore,
    public customer: Customer,
    public orderItems: Array<OrderItem>,
    public pickupDetail: OrderPickupDetail | null,
    public deliveryDetail: OrderDeliveryDetail | null,
    public orderTotal: OrderTotal,
    public orderState: OrderState,
    public customerPayment: CustomerPayment | null,
    public orderNumber: string | null,
    public orderScheduleType: OrderScheduleType,
    public orderCreatedAt: Dayjs | null,
    public orderSubmittedAt: Dayjs | null,
    public orderCompletedAt: Dayjs | null,
    public orderCanceledAt: Dayjs | null,
    public deliveryReturnedAt: Dayjs | null,
    public fulfillmentType: FulfillmentType,
    public currencyCode: string,
    public additionalNotes: string | null,
    public refunded: boolean,
    public refundType: RefundType | null,
    public refundReason: RefundReason | null,
    public refundNote: string | null,
    public canceled: boolean | null,
    public cancellationNote: string | null,
    public eta: number,
    public loyaltyPointsEarned: number,
    public promotionName: string | null,
    public applyCredits: boolean,
    public scheduledDayjs: Dayjs | null,
    public scheduledEndTime: string | null,
    public scheduledStartTime: string | null,
    public appliedPromos: Array<AppliedPromo>,
    public isCurbside: boolean,
    public curbsidePickupNotes: string | null,
    public curbsidePickupVehicleColor: string | null,
    public curbsidePickupVehicleMakeModel: string | null,
    public containsAlcohol: boolean,
    public includeUtensils: boolean,
  ) {}

  static fromDynamic(data: { [key: string]: any }) {
    const items: Array<OrderItem> = map(
      data["order_items"],
      OrderItem.fromDynamic,
    ).sort((a, b) => a.createdAt.localeCompare(b.createdAt))

    return new Order(
      data["id"],
      MerchantStore.fromDynamic(data["store"]),
      Customer.fromDynamic(data["customer"]),
      items,
      data["order_pickup_detail"].length == 0
        ? null
        : OrderPickupDetail.fromDynamic(data["order_pickup_detail"]),
      data["order_delivery_detail"].length == 0
        ? null
        : OrderDeliveryDetail.fromDynamic(data["order_delivery_detail"]),
      OrderTotal.fromDynamic(data["order_total"]),
      matchOrderState(data["order_state"]),
      data["customer_payment"] != null
        ? CustomerPayment.fromDynamic(data["customer_payment"])
        : null,
      data["external_display_id"],
      matchOrderScheduleType(data["order_schedule_type"]),
      data["order_created_at"] == null ? null : dayjs(data["order_created_at"]),
      data["order_submitted_at"] == null
        ? null
        : dayjs(data["order_submitted_at"]),
      data["order_completed_at"] == null
        ? null
        : dayjs(data["order_completed_at"]),
      data["order_canceled_at"] == null
        ? null
        : dayjs(data["order_canceled_at"]),
      data["delivery_returned_at"] == null
        ? null
        : dayjs(data["delivery_returned_at"]),
      matchFulfillmentType(data["fulfillment_type"]),
      data["currency_code"],
      data["additional_notes"],
      data["refund_detail"]["refunded"],
      matchRefundType(data["refund_detail"]["refund_type"]),
      matchRefundReason(data["refund_detail"]["refund_reason"]),
      data["refund_detail"]["refund_note"],
      data["cancellation_detail"]["canceled"],
      data["cancellation_detail"]["cancellation_note"],
      data["eta"],
      data["loyalty_points_earned"],
      data["promotion_name"],
      data["apply_credits"],
      data["scheduled_date"] == null ? null : dayjs(data["scheduled_date"]),
      data["scheduled_window_end_time"],
      data["scheduled_window_start_time"],
      data["applied_promos"] != null
        ? map(data["applied_promos"], AppliedPromo.fromDynamic)
        : [],
      data["is_curbside"],
      data["curbside_pickup_notes"],
      data["curbside_pickup_vehicle_color"],
      data["curbside_pickup_vehicle_make_model"],
      data["contains_alcohol"] == true,
      data["include_utensils"] == true,
    )
  }
}

export class OrderSummary {
  constructor(
    public id: number,
    public store: MerchantStore,
    public customerId: number,
    public orderNumber: string,
    public orderState: OrderState,
    public orderSubmittedAt: Dayjs,
    public orderTotal: OrderTotal,
    public itemCount: number,
    public itemNames: string[],
    public fulfillmentType: FulfillmentType,
    public isCurbside: boolean,
  ) {}

  static fromDynamic(data: { [key: string]: any }): OrderSummary {
    return new OrderSummary(
      data["id"],
      MerchantStore.fromDynamic(data["store"]),
      data["customer_id"],
      data["external_display_id"],
      matchOrderState(data["order_state"]),
      data["order_submitted_at"] ? dayjs(data["order_submitted_at"]) : dayjs(),
      OrderTotal.fromDynamic(data["order_total"]),
      data["item_count"],
      data["item_names"] || [],
      matchFulfillmentType(data["fulfillment_type"]),
      data["is_curbside"],
    )
  }
}
