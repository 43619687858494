import { useEffect, useRef, useState } from "react"
import { SCREEN_SIZE } from "@openui"

export const useScreenSize = () => {
  const [windowWidth, setWindowWidth] = useState(0)
  const debounce = useRef(0)

  useEffect(() => {
    if (typeof window === "undefined") {
      return
    }

    const listener = () => {
      window.cancelAnimationFrame(debounce.current)
      debounce.current = window.requestAnimationFrame(() => {
        setWindowWidth(window.innerWidth)
      })
    }
    listener()
    window.addEventListener("resize", listener)

    return () => {
      window.removeEventListener("resize", listener)
    }
  }, [])

  return {
    windowWidth,
    isMobile: windowWidth <= SCREEN_SIZE.mobile,
    isTablet:
      windowWidth <= SCREEN_SIZE.tablet && windowWidth >= SCREEN_SIZE.mobile,
    isDesktop: windowWidth >= SCREEN_SIZE.desktop,
  }
}
