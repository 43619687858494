import { useContext } from "react"
import { useStores } from "./useStores"
import { useUpdateCustomerActiveStore } from "@api/store/store"
import { IdentityContext } from "providers/IdentityProvider"

export const useActiveStore = () => {
  const { activeStoreId, setAnonymousUserActiveStore } =
    useContext(IdentityContext)
  const stores = useStores()
  const updateActiveStoreMutation = useUpdateCustomerActiveStore(
    setAnonymousUserActiveStore,
  )

  return {
    activeStore:
      stores.data?.find(({ id }) => id === activeStoreId) ??
      stores.data?.[0] ??
      null,
    updateActiveStore: updateActiveStoreMutation.mutate,
  }
}
