import React, { useState } from "react"
import { AppProps } from "next/app"
import {
  DehydratedState,
  HydrationBoundary,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query"
import { get } from "lodash"
import { APIProvider } from "@vis.gl/react-google-maps"
import { Elements } from "@stripe/react-stripe-js"
import posthog from "posthog-js"
import { createGlobalStyle } from "styled-components"
import { decompress } from "compress-json"
import { Main } from "../components/Main"
import { MerchantConfigProvider } from "providers/MerchantConfigProvider"
import { IdentityProvider } from "providers/IdentityProvider"
import { TWKLausanne } from "@openui"
import { ENV, HOST } from "@constants/env"

import { stripePromise } from "helpers/loadStripe"
import ClientSideWrapper from "@components/ClientSideWrapper"
import { useWallet } from "@api/wallet/wallet"
import useIsomorphicLayoutEffect from "hooks/useIsomorphicLayoutEffect"
import { POST_HOG_EVENTS } from "@constants/posthog"

const GOOGLE_MAP_LIBRARIES = ["marker"]

const SHOW_INTERCOM_STYLE = "showIntercom"
const HIDE_INTERCOM_STYLE = "hideIntercom"
const INTERCOM_CLASS = "intercom-lightweight-app"

const IntercomStyle = createGlobalStyle`
  .${SHOW_INTERCOM_STYLE} .${INTERCOM_CLASS} {
    opacity: 1;
    display: block !important;
  }

  .${HIDE_INTERCOM_STYLE} .${INTERCOM_CLASS} {
    opacity: 0;
    display: none !important;
  }
`

/* Client side component used to load data on the client side */
const LoadClientSide = (props: AppProps) => {
  useWallet()

  // load posthog
  useIsomorphicLayoutEffect(() => {
    posthog.init("phc_otIkZVyDJb7eAouvhXzzQZoPUGB9WyZSs7EKVnHzjg", {
      api_host: "https://us.i.posthog.com",
      person_profiles: "identified_only", // or 'always' to create profiles for anonymous users as well
      loaded: (_posthog) => {
        switch (ENV.ENV) {
          case "production": {
            window.setTimeout(() => {
              posthog.capture(POST_HOG_EVENTS.START_SESSION_RECORDING)
            }, 2000)
            break
          }
          case "staging": {
            console.log(
              "Has session recording started: " +
                _posthog.sessionRecordingStarted(),
            )
            _posthog.debug()
            window.setTimeout(() => {
              posthog.capture(POST_HOG_EVENTS.START_SESSION_RECORDING)
            }, 2000)
            break
          }
          case "development": {
            console.log(
              "Has session recording started: " +
                _posthog.sessionRecordingStarted(),
            )
            _posthog.debug()
            break
          }
        }
      },
    })
  }, [])

  useIsomorphicLayoutEffect(() => {
    switch (ENV.ENV) {
      case "production": {
        console.log = function () {}
        console.error = function () {}
        return
      }
      case "staging":
      case "development": {
        return
      }
    }
  }, [])

  useIsomorphicLayoutEffect(() => {
    if (get(props.Component, "showIntercom")) {
      document.body.classList.remove(HIDE_INTERCOM_STYLE)
      document.body.classList.add(SHOW_INTERCOM_STYLE)
    } else {
      document.body.classList.remove(SHOW_INTERCOM_STYLE)
      document.body.classList.add(HIDE_INTERCOM_STYLE)
    }
  }, [get(props.Component, "showIntercom")])

  return null
}

export default function OpenCustomer(
  props: AppProps & {
    pageProps: { host: string; dehydratedState?: DehydratedState }
  },
) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            // 30 min stale time to cache data
            staleTime: 30 * 60 * 1000,
          },
          hydrate: {
            deserializeData: (data) => {
              return decompress(data)
            },
          },
        },
      }),
  )

  if (!HOST.get()) {
    HOST.set(props.pageProps.host)
  }

  return (
    <main className={TWKLausanne.className}>
      <IntercomStyle />
      <APIProvider apiKey={ENV.GOOGLE_API} libraries={GOOGLE_MAP_LIBRARIES}>
        <QueryClientProvider client={queryClient}>
          <HydrationBoundary state={props.pageProps.dehydratedState}>
            <MerchantConfigProvider>
              <IdentityProvider>
                <Elements stripe={stripePromise}>
                  <ClientSideWrapper>
                    <LoadClientSide {...props} />
                  </ClientSideWrapper>
                  <Main {...props} />
                </Elements>
              </IdentityProvider>
            </MerchantConfigProvider>
          </HydrationBoundary>
        </QueryClientProvider>
      </APIProvider>
    </main>
  )
}
