import { useQuery } from "@tanstack/react-query"
import { first } from "lodash"
import { useActiveStore } from "./useActiveStore"
import { QUERY_KEYS } from "@api/constants"
import { Menu } from "@models/menu"
import { ONE_MINUTE } from "@constants/time"
import apiClient from "@api/client"

export const getActiveMenuQuery = ({
  storeId = null,
  omitMenuOptions = true,
}: {
  storeId: number | null
  omitMenuOptions?: boolean
}) => ({
  enabled: storeId != null,
  queryKey: [QUERY_KEYS.GET_ACTIVE_MENU, storeId, omitMenuOptions],
  // Invalidates the query to refetch the menu because of item changes
  staleTime: ONE_MINUTE,
  queryFn: async () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response = await apiClient.get<any[]>(
      `stores/${storeId}/menus/?omit_menu_options=${!!omitMenuOptions}`,
    )

    // TODO: probably change at some point
    const rawMenu = first(response?.body ?? [])
    return rawMenu ?? null
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  select: (data: { [key: string]: any } | null) => {
    return data ? Menu.fromDynamic(data) : null
  },
})

export const useActiveMenu = () => {
  const { activeStore } = useActiveStore()
  return useQuery(getActiveMenuQuery({ storeId: activeStore?.id ?? null }))
}
