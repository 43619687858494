import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useContext } from "react"
import { useOpenStripe } from "./openStripe"
import { QUERY_KEYS } from "@api/constants"
import apiClient from "@api/client"
import { Wallet } from "@models/wallet"
import { MerchantConfigContext } from "providers/MerchantConfigProvider"
import { Credit } from "@models/credit"
import { ENV } from "@constants/env"
import { IdentityContext } from "providers/IdentityProvider"
import { GiftCard } from "@models/giftCard"

export const GOOGLE_PAY_CONFIG = {
  provider: "google_pay",
  data: {
    environment: "TEST",
    apiVersion: 2,
    apiVersionMinor: 0,
    allowedPaymentMethods: [
      {
        type: "CARD",
        tokenizationSpecification: {
          type: "PAYMENT_GATEWAY",
          parameters: {
            gateway: "stripe",
            "stripe:version": "2018-10-31",
            "stripe:publishableKey": `${ENV.STRIPE_PUBLISHABLE_KEY}`,
          },
        },
        parameters: {
          allowedCardNetworks: ["VISA", "MASTERCARD"],
          allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
          billingAddressRequired: false,
          billingAddressParameters: {
            format: "FULL",
            phoneNumberRequired: false,
          },
        },
      },
    ],
    merchantInfo: {
      merchantId: "BCR2DN4TWWOOLIT7",
      merchantName: "Acelerate Inc",
    },
    transactionInfo: {
      countryCode: "US",
      currencyCode: "USD",
    },
  },
}

const getDevicePlatform = (platformPay?: {
  isApplePaySupported: boolean
  isGooglePaySupported: boolean
}) => {
  if (platformPay?.isApplePaySupported) {
    return `?device_platform=IOS`
  } else if (platformPay?.isGooglePaySupported) {
    return `?device_platform=ANDROID`
  } else return ""
}

export const useWallet = () => {
  const identity = useContext(IdentityContext)
  const { stripe, elements } = useOpenStripe()

  const queryClient = useQueryClient()
  const { config } = useContext(MerchantConfigContext)

  const { data: platformPay, isSuccess } = useQuery({
    queryKey: ["PLATFORM_PAY"],
    staleTime: Infinity,
    enabled: !!stripe && !!elements,
    queryFn: async () => {
      try {
        // To check if platform pay is supported on web
        // we make a fake payment request of $1, the user won't see anything
        // this is just seeing if the browser supports it.
        const paymentSupported = !!(
          typeof window !== undefined && window.PaymentRequest
        )
        if (!paymentSupported) {
          return {
            isApplePaySupported: false,
            isGooglePaySupported: false,
          }
        }
        const applePayJSAPI =
          paymentSupported &&
          "ApplePaySession" in window &&
          "canMakePayments" in
            (window.ApplePaySession as {
              canMakePayments: () => boolean
            }) &&
          (
            window.ApplePaySession as { canMakePayments: () => boolean }
          )?.canMakePayments()
        const applePayPaymentRequest = !applePayJSAPI
          ? await new PaymentRequest(
              [{ supportedMethods: "https://apple.com/apple-pay" }],
              {
                total: {
                  label: "Test",
                  amount: { currency: "USD", value: "1.00" },
                },
              },
            ).canMakePayment()
          : true
        const googlePaymentRequest = paymentSupported
          ? await new PaymentRequest(
              [
                {
                  supportedMethods: "https://google.com/pay",
                  data: GOOGLE_PAY_CONFIG,
                },
              ],
              {
                total: {
                  label: "Test",
                  amount: { currency: "USD", value: "1.00" },
                },
              },
            ).canMakePayment()
          : null
        const attemptPaymentRequest = stripe?.paymentRequest({
          country: "US",
          currency: "usd",
          total: {
            label: "Order Total (Test)",
            amount: 100,
          },
          requestPayerName: true,
          requestPayerEmail: true,
        })
        if (!(await attemptPaymentRequest?.canMakePayment())) {
          return {
            isApplePaySupported: false,
            isGooglePaySupported: false,
          }
        }
        return {
          isApplePaySupported: !!applePayPaymentRequest,
          isGooglePaySupported: !!googlePaymentRequest,
        }
      } catch (e) {
        console.error(e)
        return {
          isApplePaySupported: false,
          isGooglePaySupported: false,
        }
      }
    },
  })

  const wallets = useQuery({
    queryKey: [QUERY_KEYS.GET_WALLETS],
    enabled: Boolean(isSuccess && identity.identity),
    queryFn: async () => {
      if (!apiClient.hasToken) return []
      const response = await apiClient.get<{
        wallets: Array<{ [keys: string]: any }>
      }>(`user/wallet${getDevicePlatform(platformPay)}`)
      if (response?.statusCode === 200 && response.body) {
        return response.body.wallets
      }
      return []
    },
    select: (data) => data.map(Wallet.fromDynamic),
  })

  const giftCards = useQuery<Array<GiftCard>>({
    queryKey: [QUERY_KEYS.GET_GIFT_CARDS],
    queryFn: async () => {
      if (!apiClient.hasToken) return []
      const response = await apiClient.get<{ [keys: string]: any }>(
        `api/v1/merchants/${config?.merchantId}/gift-cards/`,
      )
      if (response?.statusCode === 200 && response.body)
        return response.body.gift_cards
      return []
    },
    select: (data) => data.map(GiftCard.fromDynamic),
    enabled: Boolean(identity.identity),
  })

  const credit = useQuery({
    queryKey: [QUERY_KEYS.GET_CREDIT],
    queryFn: async () => {
      if (!apiClient.hasToken) return null
      const response = await apiClient.get<{ [keys: string]: any }>(
        `api/v1/merchants/${config?.merchantId}/credit-balance/`,
      )
      if (response?.statusCode === 200 && response.body) return response.body
      return null
    },
    select: (data) => {
      return data === null ? null : Credit.fromDynamic(data)
    },
    enabled: Boolean(identity.identity),
  })

  const setDefaultWalletMutation = useMutation({
    mutationFn: ({
      walletId,
    }: {
      walletId: string
      optimisticUpdate?: boolean
    }) => {
      return apiClient.post("user/wallet/default", {
        wallet: walletId,
        ...(platformPay &&
        (platformPay.isApplePaySupported || platformPay.isGooglePaySupported)
          ? {
              device_platform: platformPay.isApplePaySupported
                ? "IOS"
                : "ANDROID",
            }
          : {}),
      })
    },
    onSuccess: (_, { walletId, optimisticUpdate = true }) => {
      if (optimisticUpdate) {
        queryClient.setQueryData(
          [QUERY_KEYS.GET_WALLETS],
          (oldWalletData: Array<{ id: string; is_default: boolean }>) => {
            return oldWalletData.map((oldData) => ({
              ...oldData,
              is_default: oldData.id === walletId,
            }))
          },
        )
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GET_WALLETS] })
    },
  })

  const setDefaultPaymentMethodMutation = useMutation({
    mutationFn: async ({ paymentMethodId }: { paymentMethodId: number }) => {
      await Promise.all([
        apiClient.post("user/wallet/INTERNAL/payment-method/default", {
          user_payment_method_id: paymentMethodId,
        }),
        setDefaultWalletMutation.mutateAsync({
          walletId: "INTERNAL",
          optimisticUpdate: false,
        }),
      ])
    },
    onSettled: (_, error, { paymentMethodId }) => {
      if (!error) {
        queryClient.setQueryData(
          [QUERY_KEYS.GET_WALLETS],
          (
            oldWalletData: Array<{
              id: string
              is_default: boolean
              payment_methods?: Array<{
                id: string
                is_default: boolean
              }>
            }>,
          ) => {
            return oldWalletData.map((oldWallet) => ({
              ...oldWallet,
              payment_methods: oldWallet.payment_methods?.map(
                (oldPaymentMethod) => ({
                  ...oldPaymentMethod,
                  is_default: `${paymentMethodId}` === `${oldPaymentMethod.id}`,
                }),
              ),
              is_default: oldWallet.id === "INTERNAL",
            }))
          },
        )
      }

      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GET_WALLETS] })
    },
  })

  const deletePaymentMethodMutation = useMutation({
    mutationFn: async ({
      paymentMethodId,
      walletType,
    }: {
      paymentMethodId: number
      walletType: string
    }) => {
      await apiClient.delete(
        `user/wallet/${walletType}/payment-method/remove`,
        {
          payment_method_id: paymentMethodId,
        },
      )
    },
    onSuccess: (_, { paymentMethodId }) => {
      queryClient.setQueryData(
        [QUERY_KEYS.GET_WALLETS],
        (
          oldWalletData: Array<{
            id: string
            is_default: boolean
            paymentMethods: Array<{
              id: string
              is_default: boolean
            }>
          }>,
        ) => {
          return oldWalletData.map((oldData) => ({
            ...oldData,
            paymentMethods: oldData.paymentMethods.filter(
              (oldPaymentMethod) =>
                `${oldPaymentMethod.id}` !== `${paymentMethodId}`,
            ),
          }))
        },
      )
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GET_WALLETS] })
    },
  })

  const redeemGiftCardMutation = useMutation({
    mutationFn: async ({ code }: { code: string }) => {
      const response = await apiClient.post<
        {
          credit_transaction: {
            id: number
            amount: {
              value: number
              currency: string
            }
            description: string
          }
        },
        { code: string }
      >(`merchants/${config?.merchantId}/gift-cards/redeem/`, {
        code,
      })
      return response?.body ?? null
    },
    onSuccess: (
      newCredit: {
        credit_transaction: {
          id: number
          amount: {
            value: number
            currency: string
          }
          description: string
        }
      } | null,
    ) => {
      queryClient.setQueryData([QUERY_KEYS.GET_CREDIT], (data: any) => {
        if ("balance" in data && typeof data.balance === "number") {
          data.balance =
            data.balance + (newCredit?.credit_transaction.amount.value ?? 0)
        }

        return {
          ...data,
        }
      })
    },
    onSettled: async () => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GET_WALLETS] }),
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GET_CREDIT] }),
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_GIFT_CARDS],
        }),
      ])
    },
  })

  return {
    credit,
    wallets,
    platformPay,
    redeemGiftCardMutation,
    setDefaultWalletMutation,
    setDefaultPaymentMethodMutation,
    deletePaymentMethodMutation,
    giftCards,
  }
}
