import { ReactNode } from "react"
import styled, { css } from "styled-components"
import { text } from "../theme"
import { Spacer } from "./Spacer"

type SegmentedButtonSize = "large" | "small"

interface Segment {
  label: ReactNode
  footer: ReactNode
  onClick: () => void
}

interface Props {
  activeIndex?: number
  size: SegmentedButtonSize
  segments: Array<Segment>
}

export const SegmentedButton = ({ activeIndex, segments, size }: Props) => {
  return (
    <Container>
      {segments.map((s, i) => (
        <SegmentContainer>
          <ButtonAndBackgroundWrapper
            size={size}
            onClick={() => {
              s.onClick()
              navigator?.vibrate(50)
            }}
          >
            <SegmentButtonBackground active={activeIndex === i} />
            <SegmentButton active={activeIndex === i}>{s.label}</SegmentButton>
          </ButtonAndBackgroundWrapper>
          <Spacer size={1} />
          <SegmentFooter>{s.footer}</SegmentFooter>
        </SegmentContainer>
      ))}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
`

const SegmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const ButtonAndBackgroundWrapper = styled.div<{ size: SegmentedButtonSize }>`
  position: relative;
  flex-basis: 0;
  ${({ size }) =>
    size === "large" ? "min-height: 68px;" : "min-height: 44px;"}
  &:hover {
    filter: brightness(1.1);
  }
`

const SegmentButton = styled.div<{ active: boolean }>`
  ${({ theme, active }) =>
    active
      ? css`
          color: ${theme.colors["Content/Primary"]};
        `
      : "color: #555D6A;"};
  ${text("Body/SemiBold")}
  border: solid 1px ${({ theme }) => theme.colors["Content/Primary"]};
  border-radius: ${({ theme }) => theme.borderRadius};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: all 0.2s;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  &:hover {
    filter: brightness(1.1);
  }
`

const SegmentButtonBackground = styled.div<{ active: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0.3;
  border-radius: ${({ theme }) => theme.borderRadius};
  ${({ theme, active }) =>
    active ? `background-color: ${theme.colors["Background/Brand"]};` : ""}
`

const SegmentFooter = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  color: #555d6a;
  ${text("Body/Regular")}
`
