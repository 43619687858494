import { map } from "lodash"
import { PaymentMethod } from "./paymentMethod"

export class Wallet {
  constructor(
    public id: "INTERNAL" | "GOOGLE_PAY" | "APPLE_PAY",
    public isDefault: boolean,
    public paymentMethods: Array<PaymentMethod>,
  ) {}

  static fromDynamic(data: { [key: string]: any }) {
    return new Wallet(
      data["id"],
      data["is_default"],
      data["payment_methods"] != null
        ? map(data["payment_methods"], PaymentMethod.fromDynamic)
        : [],
    )
  }
}
