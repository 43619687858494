export class AppliedPromo {
  constructor(
    public promoId: number,
    public promoCode: string,
    public name: string,
    public applyType: string,
    public discountAmount: number
  ) {}

  static fromDynamic(data: { [key: string]: any }): AppliedPromo {
    return new AppliedPromo(
      data["promo_id"],
      data["promo_code"],
      data["name"],
      data["apply_type"],
      data["discount_amount"]
    );
  }
}
