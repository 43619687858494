import { useContext } from "react"
import { MerchantConfigContext } from "providers/MerchantConfigProvider"
import { useInfiniteQuery } from "@tanstack/react-query"
import apiClient from "@api/client"
import { OrderSummary } from "@models/order"
import { QUERY_KEYS } from "@api/constants"
export function useOrders() {
  const merchantConfig = useContext(MerchantConfigContext)

  const currentOrders = useInfiniteQuery({
    queryKey: [QUERY_KEYS.GET_CURRENT_ORDERS],
    queryFn: async ({ pageParam }: { pageParam: number }) => {
      const res = await apiClient.get<{
        records: { [key: string]: any }[]
        more: boolean
      }>(
        `merchants/${merchantConfig.config?.merchantId}/purchases/current-orders/?offset=${pageParam * 10}&limit=10`,
      )
      return {
        records: res?.body?.records.map(OrderSummary.fromDynamic),
        more: res?.body?.more,
      }
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      const lastPageData = lastPage as {
        records: OrderSummary[]
        more: boolean
      }
      return lastPageData.more ? pages.length : undefined
    },
  })

  const completedOrders = useInfiniteQuery({
    queryKey: [QUERY_KEYS.GET_COMPLETED_ORDERS],
    queryFn: async ({ pageParam }: { pageParam: number }) => {
      const res = await apiClient.get<{
        records: { [key: string]: any }[]
        more: boolean
      }>(
        `merchants/${merchantConfig.config?.merchantId}/purchases/completed-orders/?offset=${pageParam * 10}&limit=10`,
      )
      return {
        records: res?.body?.records.map(OrderSummary.fromDynamic),
        more: res?.body?.more,
      }
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      const lastPageData = lastPage as {
        records: OrderSummary[]
        more: boolean
      }
      return lastPageData.more ? pages.length : undefined
    },
  })

  return {
    currentOrders,
    completedOrders,
  }
}
