import { CustomerPayment } from "./customerPayment"
import dayjs, { Dayjs } from "@helpers/dayjs"

export class GiftCard {
  constructor(
    public id: number,
    public code: string,
    public balance: GiftCardBalance,
  ) {}

  static fromDynamic(data: { [key: string]: any }): GiftCard {
    return new GiftCard(
      data["id"],
      data["code"],
      GiftCardBalance.fromDynamic(data["balance"]),
    )
  }
}

class GiftCardBalance {
  constructor(
    public value: number,
    public currency: string,
  ) {}

  static fromDynamic(data: { [key: string]: any }): GiftCardBalance {
    return new GiftCardBalance(data["value"], data["currency"])
  }
}

export class GiftCardSummary {
  constructor(
    public id: number,
    public giftCardLast5: string,
    public createdAt: Dayjs,
    public payment: CustomerPayment,
    public recipientFirstName: string,
    public recipientLastName: string,
    public recipientEmail: string,
    public recipientPhone: string,
  ) {}

  static fromDynamic(data: { [key: string]: any }): GiftCardSummary {
    return new GiftCardSummary(
      data["id"],
      data["gift_card_last_5"],
      dayjs(data["created_at"]),
      CustomerPayment.fromDynamic(data["payment"]),
      data["recipient_first_name"],
      data["recipient_last_name"],
      data["recipient_email"],
      data["recipient_phone"],
    )
  }
}
