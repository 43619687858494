import styled from "styled-components"
import { useContext, useRef } from "react"
import { text } from "../theme"
import { Text } from "./Text"
import { Spacer } from "./Spacer"
import { formatCurrency } from "@helpers/formatCurrency"
import { BREAKPOINT_QUERY } from "openui/media"
import useIsomorphicLayoutEffect from "hooks/useIsomorphicLayoutEffect"
import { MerchantConfigContext } from "providers/MerchantConfigProvider"

interface Props {
  imageUrl?: string | null
  name: string
  badge?: string | null
  description: string
  priceCents: number
  aspectRatio?: `${number}/${number}` | `${number}`
  theme: "card" | "row"
  responsive?: boolean
}

export const MenuItemCard = ({
  imageUrl,
  name,
  badge,
  description,
  priceCents,
  aspectRatio,
  theme,
  responsive = false,
}: Props) => {
  const { config } = useContext(MerchantConfigContext)
  const imageRef = useRef<HTMLImageElement>(null)

  useIsomorphicLayoutEffect(() => {
    if (imageRef.current) {
      if (imageUrl) {
        imageRef.current.src = imageUrl
      }

      imageRef.current.onerror = () => {
        if (imageRef.current) {
          imageRef.current.src =
            config?.itemPlaceholderUrl ?? "/item_placeholder.png"
        }
      }
    }
  }, [])

  switch (theme) {
    case "card": {
      return (
        <CardContainer $responsive={responsive}>
          {badge ? <Badge $responsive={responsive}>{badge}</Badge> : null}
          <CardImage
            $aspectRatio={aspectRatio}
            src={
              imageUrl
                ? imageUrl
                : config?.itemPlaceholderUrl
                  ? (config?.itemPlaceholderUrl ?? "/item_placeholder.png")
                  : undefined
            }
            ref={imageRef}
            $responsive={responsive}
            alt={name}
          ></CardImage>
          <Spacer size={3} />
          <CardBody $responsive={responsive}>
            <Text style="Body/Bold">{name}</Text>
            <Description>{description}</Description>
            {priceCents === 0 ? null : (
              <Text style="Body/Regular">{formatCurrency(priceCents)}</Text>
            )}
          </CardBody>
        </CardContainer>
      )
    }
    case "row": {
      return (
        <RowContainer>
          <RowBody>
            <Text style="Body/Bold">{name}</Text>
            <Description>{description}</Description>
            {priceCents === 0 ? null : (
              <Text style="Body/Regular">{formatCurrency(priceCents)}</Text>
            )}
          </RowBody>
          {imageUrl ? <RowImage ref={imageRef} alt={name}></RowImage> : null}
        </RowContainer>
      )
    }
  }
}

const Badge = styled.div<{ $responsive: boolean }>`
  ${text("Body/Regular")}

  position: absolute;
  top: 8px;
  left: 8px;
  padding: 2px 8px;
  z-index: 1;
  background: white;
  border-radius: ${({ theme }) => theme.borderRadius};

  ${({ $responsive }) =>
    $responsive
      ? BREAKPOINT_QUERY["mobile"](`
    display: none;
  `)
      : ""}
`

const CardContainer = styled.div<{ $responsive: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;

  ${({ $responsive }) =>
    $responsive
      ? `
      width: 100%;
      flex-direction: column;

      ${BREAKPOINT_QUERY["mobile"](`
        width: 100%;
        height: 100px;
        flex-direction: row;
      `)}
  `
      : `
            flex: 1;
            width: 100%;
            height: auto;
      `}
`

const RowContainer = styled.div`
  overflow: hidden;

  min-height: 140px;

  position: relative;
  display: flex;
  justify-content: space-between;

  flex-direction: row;
  width: 100%;
  border: 1px solid #e5e5e5;
  border-radius: 12px;
`

const CardImage = styled.img<{ $responsive: boolean; $aspectRatio?: string }>`
  position: relative;
  background-color: #d9d9d9;
  border-radius: ${({ theme }) => theme.borderRadius};
  object-fit: cover;
  border: 1px solid #e5e5e5;

  max-height: 300px;

  ${({ $aspectRatio }) =>
    $aspectRatio ? `aspect-ratio: ${$aspectRatio};` : ``}

  ${({ $responsive }) =>
    $responsive
      ? `
      width: auto;
      height: 220px;

       ${BREAKPOINT_QUERY["mobile"](`
          min-width: 100px;
          width: max(33%, 100px);
          height: auto;
      `)}
  `
      : `
        width: 100%;
        height: auto;
      `}
`

const RowImage = styled.img`
  position: relative;
  background-color: #d9d9d9;
  object-fit: cover;

  width: 140px;
  height: 140px;
`

const CardBody = styled.div<{ $responsive: boolean }>`
  ${({ $responsive }) =>
    $responsive
      ? `
      display: contents;

      ${BREAKPOINT_QUERY["mobile"](`
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-left: 8px;
          justify-content: flex-start;
      `)}
      `
      : ``}
`

const RowBody = styled.div`
  margin: 16px;
  display: inline-flex;
  flex-direction: column;
  width: auto;
  flex: 1;
`

const Description = styled.div`
  ${text("Body/Regular")}
  color: #555D6A;
  display: block; /* or inline-block */
  text-overflow: ellipsis;
  word-wrap: break-word;

  margin: 4px 0px;

  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;

  overflow: hidden;
  line-height: 1.3em;
`
