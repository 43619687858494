import { useCallback, useContext, useState } from "react"
import Link from "next/link"
import styled, { useTheme } from "styled-components"
import { useRouter } from "next/router"
import { HeaderBanner } from "./HeaderBanner"
import { BREAKPOINT_QUERY, Icon, IconKey, Text, text } from "@openui"
import { MerchantConfigContext } from "providers/MerchantConfigProvider"
import { IdentityContext } from "providers/IdentityProvider"
import { useCart } from "hooks/useCart"

const HEADER_HEIGHT = `85px`

const HeaderComponent = styled.div`
  position: relative;
  height: ${HEADER_HEIGHT};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px black;
  padding: 17px 40px;
  z-index: 100;

  background-color: ${({ theme }) => theme.colors["Background/Brand"]};

  ${BREAKPOINT_QUERY["mobile"](`
    padding: 16px 12px;
  `)}

  & a {
    color: inherit; /* blue colors for links too */
    text-decoration: inherit; /* no underline */
  }
`
const HeaderMenuContainer = styled.div<{
  $isHeaderShown: boolean
}>`
  transition: max-height 350ms ease-out;
  overflow: hidden;

  ${({ $isHeaderShown }) =>
    BREAKPOINT_QUERY["mobile"](`
      position: absolute;
      max-height: ${$isHeaderShown ? "100vh" : 0};

      top: ${HEADER_HEIGHT};
      height: 100vh;
      width: 100vw;
      left: 0;
      bottom: 0;
  `)}

  ${BREAKPOINT_QUERY["mobile"](`
    display: flex;
  `)}
`
const HeaderMenu = styled.div`
  display: flex;
  grid-auto-flow: column;
  grid-gap: 40px;

  background-color: ${({ theme }) => theme.colors["Background/Brand"]};
  padding: 17px 16px;

  & a {
    cursor: pointer;
  }

  ${BREAKPOINT_QUERY["mobile"](`
      display: flex;
      flex-direction: column;
      align-items: start;
      grid-gap: 8px;

      height: calc(100% - ${HEADER_HEIGHT});
      width: 100vw;
      flex-direction: column;
      padding: 32px 12px;
  `)}
`

const HeaderLink = styled.div<{
  $showMobile?: boolean
  $showDesktop?: boolean
}>`
  display: ${({ $showDesktop }) => ($showDesktop ? "block" : "none")};

  ${({ $showMobile }) =>
    BREAKPOINT_QUERY["mobile"](`
      display: ${$showMobile ? "grid" : "none"};
      `)}
`

const HeaderItem = styled.div<{
  $title: string
  $isSelected?: boolean
}>`
  display: grid;
  grid-template: "title";
  text-align: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors["Content/Brand"]};

  ${({ $isSelected }) =>
    $isSelected ? text("Subheading/Bold") : text("Subheading/Regular")}

  &:hover {
    ${text("Subheading/Bold")}
  }

  &::before {
    opacity: 0;
    visibility: hidden;
    content: "${({ $title }) => $title}";
    left: 0;
    top: 0;
    height: 100%;
    ${text("Body/SemiBold")}
  }

  &::before,
  & span {
    grid-area: title;
  }

  ${() =>
    BREAKPOINT_QUERY["mobile"](`
      display: grid;

      margin-bottom: 20px;
      font-size: 20px;

      &:hover {
        font-size: 20px;
      }
  `)}
`
const HeaderLogoLink = styled(Link)`
  width: 80px;
`

const HEADER_PADDING_Y = 32
const HeaderLogo = styled.img`
  position: absolute;
  top: ${HEADER_PADDING_Y / 2}px;
  height: 100%;
  max-height: calc(100% - ${HEADER_PADDING_Y}px);
  object-fit: contain;
  width: auto;
  max-width: min(40%, 300px);
`
const HeaderButtons = styled.div`
  display: inline-flex;
  align-items: center;
`
const HeaderButton = styled.div`
  cursor: pointer;
  display: inline;
  margin-left: 16px;
  position: relative;

  ${BREAKPOINT_QUERY["mobile"](`
    display: none;
  `)}
`

const MobileHeaderButton = styled.div`
  cursor: pointer;
  display: none;

  ${BREAKPOINT_QUERY["mobile"](`
    display: inline;
  `)}
`

const CartItemIndicator = styled.div`
  width: 16px;
  height: 16px;
  background-color: ${({ theme }) => theme.colors["Content/Error"]};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -6px;
  right: -6px;
`

const HEADER_ITEMS = [
  {
    name: "Menu",
    href: "/",
    showDesktop: true,
    showMobile: true,
  },
  {
    name: "Orders",
    href: "/orders",
    showLogin: true,
    showDesktop: true,
    showMobile: true,
  },
  {
    name: "Locations",
    href: "/locations",
    showDesktop: true,
    showMobile: true,
  },
  {
    name: "Rewards",
    href: "/rewards",
    showDesktop: true,
    showMobile: true,
  },
  {
    name: "Gift Cards",
    href: "/gift-cards",
    showDesktop: true,
    showMobile: true,
  },
  {
    name: "Login/Sign Up",
    href: "/login",
    showLogout: true,
    showDesktop: false,
    showMobile: true,
  },
  {
    name: "Cart",
    href: "/cart",
    showLogin: true,
    showDesktop: false,
    showMobile: true,
  },
  {
    name: "Settings",
    href: "/settings",
    showLogin: true,
    showDesktop: false,
    showMobile: true,
  },
]

export const Header = () => {
  const theme = useTheme()
  const merchantConfig = useContext(MerchantConfigContext)

  const identity = useContext(IdentityContext)
  const { cart } = useCart()
  const router = useRouter()
  const [isHeaderShown, setIsHeaderShown] = useState(false)

  const handleMenuOpen = useCallback(() => {
    setIsHeaderShown(true)
  }, [])
  const handleMenuClose = useCallback(() => {
    setIsHeaderShown(false)
  }, [])

  return (
    <>
      <HeaderBanner />
      <HeaderComponent>
        <HeaderLogoLink href="/">
          <HeaderLogo
            src={merchantConfig.config?.webLogoUrl}
            alt={merchantConfig.config?.displayName ?? "Store"}
          />
        </HeaderLogoLink>
        <HeaderMenuContainer $isHeaderShown={isHeaderShown}>
          <HeaderMenu>
            {HEADER_ITEMS.map((item) => {
              return !!(item.showLogin ? identity.identity : true) &&
                !!(item.showLogout ? !identity.identity : true) ? (
                <HeaderLink
                  key={`${item.name}:${identity.identity?.user.id ?? ""}`}
                  $showMobile={item.showMobile}
                  $showDesktop={item.showDesktop}
                >
                  <Link prefetch={true} href={item.href}>
                    <HeaderItem
                      $title={item.name}
                      $isSelected={item.href === router.pathname}
                      onClick={handleMenuClose}
                    >
                      <span>{item.name}</span>
                    </HeaderItem>
                  </Link>
                </HeaderLink>
              ) : null
            })}
          </HeaderMenu>
        </HeaderMenuContainer>
        <HeaderButtons>
          <MobileHeaderButton
            onClick={isHeaderShown ? handleMenuClose : handleMenuOpen}
          >
            {isHeaderShown ? (
              <Icon
                size={24}
                iconKey={IconKey.Close}
                color={theme["colors"]["Content/Brand"]}
              />
            ) : (
              <Icon
                size={24}
                iconKey={IconKey.Menu}
                color={theme["colors"]["Content/Brand"]}
              />
            )}
          </MobileHeaderButton>

          {!identity.identity ? (
            <HeaderButton>
              <HeaderLink $showMobile $showDesktop>
                <Link href="/login">
                  <HeaderItem
                    $title="login"
                    $isSelected={"/login" === router.pathname}
                  >
                    <span>Login</span>
                  </HeaderItem>
                </Link>
              </HeaderLink>
            </HeaderButton>
          ) : (
            <>
              <HeaderButton>
                <Link prefetch={true} href="/cart">
                  <Icon
                    size={24}
                    iconKey={IconKey.Cart}
                    color={theme["colors"]["Content/Brand"]}
                  />
                </Link>
                {!!cart.data?.orderItems.length && (
                  <CartItemIndicator>
                    <Text style="Label/Regular" color="Background/Primary">
                      {(cart.data?.orderItems ?? []).reduce(
                        (acc, add) => acc + add.quantity,
                        0,
                      )}
                    </Text>
                  </CartItemIndicator>
                )}
              </HeaderButton>
              <HeaderButton>
                <Link prefetch={true} href="/settings">
                  <Icon
                    size={24}
                    iconKey={IconKey.Cog}
                    color={theme["colors"]["Content/Brand"]}
                  />
                </Link>
              </HeaderButton>
            </>
          )}
        </HeaderButtons>
      </HeaderComponent>
    </>
  )
}
