import React from "react"
import { Text } from "openui/components/Text"
import { useTheme } from "styled-components"
import { Icon, IconKey } from "./Icon"
import { Row } from "./Row"
import { Spacer } from "./Spacer"

interface Props {
  label: string
  icon: IconKey
  onClick: () => void
  hasEllipsis?: boolean
}

export const SettingsLineItem = ({
  label,
  icon,
  onClick,
  hasEllipsis = true,
}: Props) => {
  const theme = useTheme()
  return (
    <Row
      onClick={onClick}
      padding="16px 0"
      justifyContent="space-between"
      alignItems="center"
      cursor="pointer"
    >
      <Row alignItems="center">
        <Icon
          size={24}
          iconKey={icon}
          color={theme.colors["Background/Brand"]}
        />
        <Spacer size={2} $vertical />
        <Text style="Body/SemiBold">{label}</Text>
      </Row>
      {hasEllipsis && (
        <Icon size={24} iconKey={IconKey.EllipsisVertical} color={"#808080"} />
      )}
    </Row>
  )
}
