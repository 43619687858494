import { map } from "lodash"
import { MenuItemOptionGroup } from "./menuItemOptionGroup"

export class MenuItemOption {
  constructor(
    public id: number,
    public name: string,
    public isAvailable: boolean,
    public priceCents: number,
    public sortOrder: number,
    public isDefault: boolean,
    public allowsDuplicates: boolean,
    public nestedOptionGroups: Array<MenuItemOptionGroup>,
  ) {}

  public toString(): string {
    return `${this.name}; available: ${this.isAvailable}; price: ${this.priceCents}; option group count: ${this.nestedOptionGroups.length}`
  }

  static fromDynamic(data: { [key: string]: any }) {
    const optionGroups: Array<MenuItemOptionGroup> = map(
      data["nested_option_groups"] ?? [],
      MenuItemOptionGroup.fromDynamic,
    )
    // sort based on sort_order
    optionGroups.sort((a, b) => a.sortOrder - b.sortOrder)

    return new MenuItemOption(
      data["id"],
      data["name"],
      data["is_available"],
      data["price_cents"],
      data["sort_order"],
      data["is_default"] ?? false,
      data["allows_duplicates"] ?? false,
      optionGroups,
    )
  }
}
