import styled from "styled-components"
import { text } from "../theme"

interface Props {
  placeholder?: string
  height?: string
  resize?: "vertical" | "horizontal" | "none" | "all"
  value: string
  onChange: (value: string) => void
  onBlur?: () => void
  onSubmit?: () => void
  autoFocus?: boolean
  disabled?: boolean
}

export const InputTextArea = ({
  placeholder,
  resize = "none",
  value,
  height,
  onChange,
  onBlur,
  onSubmit,
  autoFocus,
  disabled,
}: Props) => {
  return (
    <Input
      $height={height}
      $resize={resize}
      value={value}
      placeholder={placeholder}
      onChange={(e) => onChange(e.target.value)}
      onBlur={onBlur}
      onSubmit={onSubmit}
      autoFocus={autoFocus}
      disabled={disabled}
    />
  )
}

const Input = styled.textarea<{ $height?: string; $resize: Props["resize"] }>`
  ${({ $height }) =>
    $height !== undefined ? `height: ${$height}; min-height: ${$height};` : ``}

  ${({ $resize }) => `resize: ${$resize};`}
  border: solid 1px ${({ theme }) => theme.colors["Border/Primary"]};
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: 12px;
  width: 100%;
  resize: none;
  background-color: transparent;
  ${text("Body/Regular")}
`
