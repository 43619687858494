import { getCookie, setCookie, removeCookie } from "typescript-cookie"
import { ENV } from "@constants/env"
import packageJSON from "package.json"

export const IDENTITY_TOKEN_KEY = "OpenIdentity"

export type ResponseWrapper<T> = {
  statusCode: number
  body: T | null
}

class APIClientFactory {
  private token: string | null = null
  private appVersion: string = packageJSON.version

  private headers: Headers

  get hasToken() {
    return !!this.token
  }

  constructor() {
    this.headers = new Headers()
    this.headers.append("Content-Type", "application/json; charset=UTF-8")
    this.headers.append("App-Version", this.appVersion)

    if (typeof window !== "undefined") {
      this.token =
        localStorage.getItem(IDENTITY_TOKEN_KEY) ??
        getCookie(IDENTITY_TOKEN_KEY) ??
        null

      if (this.token) {
        this.headers.append("Authorization", `Bearer ${this.token}`)
      }
    }
  }

  private async wrapResponse<Response>(
    response: globalThis.Response,
  ): Promise<ResponseWrapper<Response> | null> {
    let clonedResponse: globalThis.Response | null = null

    if (ENV.ENV != "production" || !response.ok) {
      console.debug(`Fetched: ${response.url}`)
      clonedResponse = response.clone()
    }

    try {
      const bodyAsString = await response.text()
      const body = bodyAsString ? JSON.parse(bodyAsString) : null
      return {
        statusCode: response.status,
        body,
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e) {
      const bodyAsString = await clonedResponse?.text()

      const body = (() => {
        try {
          return bodyAsString ? JSON.parse(bodyAsString) : null
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (e) {
          return null
        }
      })()

      if (ENV.ENV != "production") {
        console.debug(`${response.url}: ${bodyAsString}`)
      }

      return {
        statusCode: response.status,
        body,
      }
    }
  }

  setToken(newToken: string | null) {
    if (typeof window === "undefined") {
      return
    }

    if (newToken) {
      this.token = newToken
      if (typeof window !== "undefined") {
        localStorage.setItem(IDENTITY_TOKEN_KEY, newToken)
        setCookie(IDENTITY_TOKEN_KEY, newToken)
      }
      this.headers.delete("Authorization")
      this.headers.append("Authorization", `Bearer ${newToken}`)
    } else {
      if (typeof window !== "undefined") {
        localStorage.removeItem(IDENTITY_TOKEN_KEY)
      }
      this.token = null
      removeCookie(IDENTITY_TOKEN_KEY)
      this.headers.delete("Authorization")
    }
  }

  async get<Response>(
    path: string,
    token?: string | null,
  ): Promise<ResponseWrapper<Response> | null> {
    const headerValues = new Headers(this.headers)

    if (token) {
      headerValues.delete("Authorization")
      headerValues.append("Authorization", `Bearer ${token}`)
    }

    const response = await fetch(`${ENV.API_BASE_URL}/${path}`, {
      headers: headerValues,
    })

    return this.wrapResponse<Response>(response)
  }

  async post<Response, Body>(
    path: string,
    requestBody: Body,
    token?: string | null,
  ): Promise<ResponseWrapper<Response> | null> {
    const headerValues = new Headers(this.headers)

    if (token) {
      headerValues.delete("Authorization")
      headerValues.append("Authorization", `Bearer ${token}`)
    }

    const response = await fetch(`${ENV.API_BASE_URL}/${path}`, {
      headers: headerValues,
      method: "POST",
      body: JSON.stringify(requestBody),
    })

    return this.wrapResponse<Response>(response)
  }

  async put<Response, Body>(
    path: string,
    requestBody: Body,
    token?: string | null,
  ): Promise<ResponseWrapper<Response> | null> {
    const headerValues = new Headers(this.headers)

    if (token) {
      headerValues.delete("Authorization")
      headerValues.append("Authorization", `Bearer ${token}`)
    }

    const response = await fetch(`${ENV.API_BASE_URL}/${path}`, {
      headers: headerValues,
      method: "PUT",
      body: JSON.stringify(requestBody),
    })

    return this.wrapResponse<Response>(response)
  }

  async delete<Response, Body>(
    path: string,
    requestBody: Body,
    token?: string | null,
  ): Promise<ResponseWrapper<Response> | null> {
    const headerValues = new Headers(this.headers)

    if (token) {
      headerValues.delete("Authorization")
      headerValues.append("Authorization", `Bearer ${token}`)
    }

    const response = await fetch(`${ENV.API_BASE_URL}/${path}`, {
      headers: headerValues,
      method: "DELETE",
      body: JSON.stringify(requestBody),
    })

    return this.wrapResponse<Response>(response)
  }

  async postStream<Body>(
    path: string,
    requestBody: Body,
    token?: string | null,
  ) {
    const headerValues = new Headers(this.headers)

    if (token) {
      headerValues.delete("Authorization")
      headerValues.append("Authorization", `Bearer ${token}`)
    }

    const response = await fetch(`${ENV.API_BASE_URL}/${path}`, {
      headers: headerValues,
      method: "POST",
      body: JSON.stringify(requestBody),
    })

    return response.body?.getReader() ?? null
  }
}

const apiClient = new APIClientFactory()

export default apiClient
