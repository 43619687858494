import { StoreAddress } from "./storeAddress"

export class PrepTime {
  constructor(
    public minutes: number,
    public seconds: number,
  ) {}

  static fromDynamic(data: { [key: string]: any }) {
    return new PrepTime(data["minutes"] ?? 15, data["seconds"] ?? 0)
  }
}

export class PackagingPreferences {
  constructor(
    public enabled: boolean,
    public label: string,
  ) {}

  static fromDynamic(data: { [key: string]: any }) {
    return new PackagingPreferences(data["enabled"] ?? false, data["label"] ?? "Include utensils and condiments")
  }
}

export class MerchantStore {
  constructor(
    public id: number,
    public name: string,
    public taxRate: number,
    public merchantId: number,
    public address: StoreAddress,
    public distanceFromLocation: number | null = null,
    public isOrderingPaused = false,
    public isDeliveryPaused = false,
    public orderFallbackEnabled = false,
    public isOpen: boolean,
    public phone: string, // will have + prefix to all phone numbers
    public prepTime: PrepTime,
    public allowSpecialInstructions: boolean,
    public timezone: string,
    public isCurbsideEnabled: boolean,
    public curbsideInstructions: string | null = null,
    public coverImageURL: string | null = null,
    public fallbackOrderUrl: string | null = null,
    public packagingPreferences: PackagingPreferences | null = null,
  ) {}

  getStoreInfo(): string {
    return `${this.name}\n${this.address.toString()}`
  }

  static fromDynamic(data: { [key: string]: any }) {
    return new MerchantStore(
      data["id"],
      data["name"],
      parseInt(data["tax_rate"]), // TODO: update this once String -> double in the backend
      data["merchant_id"],
      StoreAddress.fromDynamic(data["address"]),
      data["distance"],
      data["is_ordering_paused"] == true,
      data["is_delivery_paused"] == true,
      data["order_fallback_enabled"] == true,
      data["is_open"],
      data["phone"],
      data["prep_time"] != null
        ? PrepTime.fromDynamic(data["prep_time"])
        : new PrepTime(15, 0),
      data["special_instruction_enabled"] ?? false,
      data["timezone"],
      data["curbside_enabled"],
      data["curbside_instructions"],
      data["cover_image_url"],
      data["fallback_order_url"],
      data["packaging_preferences"] != null
        ? PackagingPreferences.fromDynamic(data["packaging_preferences"])
        : null,
    )
  }
}
