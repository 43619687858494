// Please also update './next.config.json
export const ENV = {
  ENV: process.env.ENV as "production" | "staging" | "development",
  API_BASE_URL: process.env.API_BASE_URL,
  SENTRY_URL: process.env.SENTRY_URL,
  GOOGLE_API: process.env.GOOGLE_API!,
  OVERRIDE_DOMAIN: process.env.OVERRIDE_DOMAIN,
  STRIPE_PUBLISHABLE_KEY: process.env.STRIPE_PUBLISHABLE_KEY!,
} as const

// eslint-disable-next-line prefer-const
export let HOST = (() => {
  let host = ""

  return {
    set: (_host: string) => (host = _host),
    get: () => host,
  }
})()
