import { map, sum } from "lodash"
import {
  OrderItemOption,
  RefundReason,
  RefundType,
  matchRefundReason,
  matchRefundType,
} from "./orderItemOption"

export class OrderItem {
  constructor(
    public id: number,
    public createdAt: string,
    public orderId: number,
    public menuItemId: number,
    public menuId: number,
    public orderItemOptions: Array<OrderItemOption>,
    public quantity: number,
    public quantityAdjustments: number,
    public price: number,
    public name: string,
    public isReward: boolean | null = null,
    public itemImgSrc: string | null = null,
    public categoryName: string | null = null,
    public specialInstructions: string | null = null,
    public refunded = false,
    public refundType: RefundType | null = null,
    public refundReason: RefundReason | null = null,
    public refundNote: string | null = null,
    public rewardPointsUsed: number,
    public isAvailable: boolean,
  ) {}

  public itemTotal(): number {
    const totalOptionPrice: number = sum(
      this.orderItemOptions
        .filter((o) => o.parentOrderItemOptionId === null)
        .map((option) => {
          const nestedOptions = this.orderItemOptions.filter(
            (o) => o.parentOrderItemOptionId === option.id,
          )
          const nestedOptionsPrice = sum(
            nestedOptions.map((o) => {
              return o.price * o.quantity
            }),
          )
          return (option.price + nestedOptionsPrice) * option.quantity
        }),
    )
    return this.quantity * (totalOptionPrice + this.price)
  }

  static fromDynamic(data: { [key: string]: any }) {
    return new OrderItem(
      data["id"],
      data["created_at"],
      data["order_id"],
      data["menu_item_id"],
      data["menu_id"],
      map(data["order_item_options"] ?? [], OrderItemOption.fromDynamic),
      data["quantity"],
      data["quantity_adjustments"],
      data["price"],
      data["name"],
      data["is_reward"],
      data["item_img_src"],
      data["category_name"],
      data["special_instructions"],
      data["refunded"],
      matchRefundType(data["refund_type"]),
      matchRefundReason(data["refund_reason"]),
      data["refund_note"],
      data["reward_points_used"],
      data["is_available"],
    )
  }
}
