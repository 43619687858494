import { map } from "lodash";
import { MenuItemOption } from "./menuItemOption";

export class MenuItemOptionGroup {
  constructor(
    public id: number,
    public name: string,
    public description: string | null,
    public minSelections: number,
    public maxSelections: number | null,
    public sortOrder: number,
    public options: Array<MenuItemOption>
  ) {}

  static fromDynamic(data: { [key: string]: any }) {
    const itemOptions: Array<MenuItemOption> = map(
      data["menu_item_options"],
      MenuItemOption.fromDynamic
    );
    // sort based on sort_order
    itemOptions.sort(
      (a: MenuItemOption, b: MenuItemOption) => a.sortOrder - b.sortOrder
    );

    return new MenuItemOptionGroup(
      data["id"],
      data["name"],
      null,
      data["min_selections"],
      data["max_selections"],
      data["sort_order"],
      itemOptions
    );
  }
}
