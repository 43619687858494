import Intercom, {
  hide,
  show,
  showSpace,
  update,
} from "@intercom/messenger-js-sdk"
import { useCallback, useContext, useEffect } from "react"
import { IdentityContext } from "providers/IdentityProvider"
import { MerchantConfigContext } from "providers/MerchantConfigProvider"

export const INTERCOM_APP_ID = "wb2w37my"

let isIntercomLoaded = false

export const useIntercom = () => {
  const { config } = useContext(MerchantConfigContext)
  const { identity } = useContext(IdentityContext)

  useEffect(() => {
    if (!isIntercomLoaded) {
      isIntercomLoaded = true
      Intercom({
        app_id: INTERCOM_APP_ID,
        hide_default_launcher: true,
      })
    }
  }, [])

  const show = useCallback(() => {
    if (!window.Intercom) return
    window.Intercom("update", {
      hide_default_launcher: false,
    })
  }, [])

  const hide = useCallback(() => {
    if (!window.Intercom) return
    window.Intercom("update", {
      hide_default_launcher: true,
    })
  }, [])

  const open = useCallback(() => {
    showSpace("home")
  }, [])

  useEffect(() => {
    if (identity?.user.firstName && identity.customer) {
      update({
        email: `accounts+${identity.customer.id.toString()}@getopen.com`,
        name: identity.user.firstName,
        phone: identity.user.phone,
        user_id: identity.customer.id.toString(),
        merchant_name: config?.displayName,
      })
    }
  }, [
    identity?.user.firstName,
    identity?.user.id,
    identity?.customer.id,
    config?.displayName,
  ])

  return {
    show,
    hide,
    open,
  }
}
