import { useContext } from "react"
import { useQuery } from "@tanstack/react-query"
import { identity } from "lodash"

import { useGeolocation } from "./useGeolocation"
import apiClient from "@api/client"
import { QUERY_KEYS } from "@api/constants"
import { LatLng } from "@models/latLng"
import { MerchantStore } from "@models/store"
import { MerchantConfigContext } from "providers/MerchantConfigProvider"

export const getStoresQuery = (merchantId: number, geolocation?: LatLng) => {
  return {
    queryKey: [
      QUERY_KEYS.GET_STORES,
      merchantId,
      geolocation?.latitude ?? null,
      geolocation?.longitude ?? null,
    ],
    queryFn: async () => {
      let url = `merchants/${merchantId}/stores/`
      if (geolocation) {
        url += `?lat=${geolocation.latitude}&lng=${geolocation.longitude}&limit=100&offset=0`
      }
      const response = await apiClient.get<{
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        stores: Array<{ [keys: string]: any }>
      }>(url)
      if (response?.statusCode === 200) {
        try {
          return response.body
        } catch (e) {
          console.error(e)
          throw new Error("Unable to serialize MerchantStores")
        }
      }
      return null
    },
    placeholderData: identity,
    select: (
      data: {
        stores: Array<{
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          [keys: string]: any
        }>
      } | null,
    ) => {
      if (data) {
        return data.stores?.map(MerchantStore.fromDynamic) ?? null
      }

      return null
    },
  } as const
}

export const useStores = () => {
  const merchantConfig = useContext(MerchantConfigContext)
  const geolocation = useGeolocation()

  const storeQuery = useQuery(
    getStoresQuery(
      merchantConfig.config?.merchantId ?? 0,
      geolocation.location ?? undefined,
    ),
  )

  return storeQuery
}
