import { useState } from "react"
import { useQuery } from "@tanstack/react-query"
import { LatLng } from "@models/latLng"
import { QUERY_KEYS } from "@api/constants"

export const useGeolocation = () => {
  const [isAwaitingPermission, setIsAwaitingPermission] = useState(true)
  const [isPermissionDenied, setIsPermissionDenied] = useState(false)

  const { data: location, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.GET_LOCATION],
    enabled: typeof window !== "undefined",
    staleTime: Infinity,
    queryFn: async () => {
      const permission = await navigator.permissions.query({
        name: "geolocation",
      })

      switch (permission.state) {
        case "denied": {
          setIsAwaitingPermission(false)
          setIsPermissionDenied(true)
        }
        case "granted":
        case "prompt": {
          setIsAwaitingPermission(false)
          setIsPermissionDenied(false)

          return new Promise<LatLng>((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                resolve(
                  new LatLng(
                    position.coords.latitude,
                    position.coords.longitude,
                  ),
                )
              },
              (e) => {
                reject(0)
                console.error(e)
              },
            )
          })
        }
      }
    },
  })

  return {
    isAwaitingPermission,
    isPermissionDenied,
    isLoading,
    location,
  }
}
