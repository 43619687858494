import { css } from "styled-components"
import { ThemeProps } from "./theme"

export interface TextStyle {
  fontSize: string
  fontWeight: string
  lineHeight: string
  fontFamily: string
}

export type TextStyles =
  | "Body/Small"
  | "Body/Regular"
  | "Body/Medium"
  | "Body/SemiBold"
  | "Body/Bold"
  | "Label/Regular"
  | "LabelMultiline/Regular"
  | "Heading/Medium"
  | "Heading/SemiBold"
  | "Heading/Bold"
  | "Subheading/Regular"
  | "Subheading/Bold"
  | "DisplayHeading/Large"
  | "DisplayHeading/XLarge"

export const text =
  (style: TextStyles) =>
  (props: ThemeProps): ReturnType<typeof css> => {
    const textStyle = props.theme.text[style]
    return css`
      font-size: ${textStyle.fontSize};
      font-family: ${textStyle.fontFamily};
      font-weight: ${textStyle.fontWeight};
      line-height: ${textStyle.lineHeight};
      letter-spacing: 0.5px;
    `
  }
