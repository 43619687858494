import { map } from "lodash"
import { MenuItemOptionGroup } from "./menuItemOptionGroup"

export class MenuItem {
  constructor(
    public id: number,
    public storeMenuId: number,
    public name: string,
    public description: string,
    public priceCents: number,
    public taxRate: string,
    public imageUrl: string | null,
    public optionGroups: Array<MenuItemOptionGroup>,
    public sortOrder: number,
    public isAvailable: boolean,
    public isHidden: boolean,
    public rewardEligible: boolean,
    public specialInstructionEnabled: boolean,
  ) {}

  static fromDynamic(data: { [key: string]: any }) {
    const optionGroups: Array<MenuItemOptionGroup> = map(
      data["menu_item_option_groups"],
      MenuItemOptionGroup.fromDynamic,
    )
    // Sort based on sort_order.
    optionGroups.sort((a, b) => a.sortOrder - b.sortOrder)
    // Regardless of sort order, show required option groups first.
    optionGroups.sort((a, b) => b.minSelections - a.minSelections)

    return new MenuItem(
      data["id"],
      data["store_menu_id"],
      data["name"],
      data["description"],
      data["price_cents"],
      data["tax_rate"],
      data["image_url"] ?? null,
      optionGroups,
      data["sort_order"],
      data["is_available"],
      data["is_hidden"],
      data["reward_eligible"] ?? false,
      data["special_instruction_enabled"] ?? false,
    )
  }
}
