export enum RefundType {
  partial = "partial",
  full = "full"
}

export enum RefundReason {
  fulfillmentError = "fulfillmentError",
  outOfStock = "outOfStock"
}

export const matchRefundType = (refundType: string): RefundType | null => {
  switch (refundType) {
    case "PARTIAL_REFUND":
      return RefundType.partial;
    case "FULL_REFUND":
      return RefundType.full;
    default:
      return null;
  }
};

export const matchRefundReason = (reason: string): RefundReason | null => {
  switch (reason) {
    case "FULFILLMENT_ERROR":
      return RefundReason.fulfillmentError;
    case "OUT_OF_STOCK":
      return RefundReason.outOfStock;
    default:
      return null;
  }
};

export class OrderItemOption {
  constructor(
    public id: number,
    public quantity: number,
    public quantityAdjustments: number | null = null,
    public price: number,
    public name: string,
    public optionGroupName: string,
    public refunded: boolean | null = null,
    public refundType: RefundType | null = null,
    public refundReason: RefundReason | null = null,
    public refundNote: string | null = null,
    public orderItemId: number,
    public menuItemOptionId: number,
    public parentOrderItemOptionId: number | null = null
  ) {}

  static fromDynamic(data: { [key: string]: any }) {
    return new OrderItemOption(
      data["id"],
      data["quantity"],
      data["quantity_adjustments"],
      data["price"],
      data["name"],
      data["option_group_name"],
      data["refunded"],
      matchRefundType(data["refund_type"]),
      matchRefundReason(data["refund_reason"]),
      data["refund_note"],
      data["order_item_id"],
      data["menu_item_option_id"],
      data["parent_order_item_option_id"]
    );
  }
}
