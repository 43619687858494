import { FaSpinner } from "react-icons/fa"
import styled, { keyframes } from "styled-components"
import { Icon, IconKey } from "./Icon"

interface Props {
  size?: number
  color?: string
}

export const Loading = ({ size = 16, color }: Props) => {
  return (
    <Container size={size}>
      <Icon size={size} iconKey={IconKey.Spinner} color={color} />
    </Container>
  )
}

const spinAnimation = keyframes`
  from { transform:rotate(0deg); }
  to { transform:rotate(360deg); }
`

const Container = styled.div<{ size: number }>`
  ${({ size }) =>
    `
    min-width: ${size}px;
    max-width: ${size}px;
    min-height: ${size}px;
    max-height: ${size}px;
  `}
  animation-name: ${spinAnimation};
  animation-duration: 1s;
  animation-iteration-count: infinite;
`
