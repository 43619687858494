import styled from "styled-components"

interface Props {
  justifyContent?: "flex-start" | "flex-end" | "center"
  alignItems?: "flex-start" | "flex-end" | "center"
  width?: string
  height?: string
  padding?: string
  gap?: string
  cursor?: "default" | "inherit" | "pointer"
}

export const Column = styled.div<Props>`
  width: ${({ width }) => width ?? "auto"};
  height: ${({ height }) => height ?? "fit-content"};
  gap: ${({ gap }) => gap ?? "0"};
  display: flex;
  flex-direction: column;
  justify-content: ${({ justifyContent }) => justifyContent ?? "flex-start"};
  align-items: ${({ alignItems }) => alignItems ?? "flex-start"};
  padding: ${({ padding }) => padding ?? "0"};
  cursor: ${({ cursor }) => cursor ?? "inherit"};
`
