import { MouseEventHandler, PropsWithChildren } from "react"
import styled from "styled-components"

interface Props {
  justifyContent?:
    | "flex-start"
    | "flex-end"
    | "center"
    | "space-between"
    | "space-evenly"
  alignItems?: "flex-start" | "flex-end" | "center"
  width?: string
  height?: string
  padding?: string
  gap?: string
  wrap?: "nowrap" | "wrap" | "wrap-reverse"
  cursor?: "default" | "inherit" | "pointer"
  onClick?: MouseEventHandler<HTMLDivElement>
}

export const Row = ({
  justifyContent,
  alignItems,
  width,
  height,
  padding,
  gap,
  wrap,
  cursor,
  children,
  onClick,
}: PropsWithChildren<Props>) => {
  return (
    <Component
      $justifyContent={justifyContent}
      $alignItems={alignItems}
      $width={width}
      $height={height}
      $padding={padding}
      $gap={gap}
      $wrap={wrap}
      $cursor={cursor}
      onClick={onClick}
    >
      {children}
    </Component>
  )
}

const Component = styled.div<{
  $justifyContent?:
    | "flex-start"
    | "flex-end"
    | "center"
    | "space-between"
    | "space-evenly"
  $alignItems?: "flex-start" | "flex-end" | "center"
  $width?: string
  $height?: string
  $padding?: string
  $gap?: string
  $wrap?: "nowrap" | "wrap" | "wrap-reverse"
  $cursor?: "default" | "inherit" | "pointer"
}>`
  width: ${({ $width }) => $width ?? "100%"};
  height: ${({ $height }) => $height ?? "auto"};
  gap: ${({ $gap }) => $gap ?? "0"};
  display: flex;
  flex-direction: row;
  justify-content: ${({ $justifyContent }) => $justifyContent ?? "flex-start"};
  align-items: ${({ $alignItems }) => $alignItems ?? "flex-start"};
  flex-wrap: ${({ $wrap }) => $wrap ?? "nowrap"};
  padding: ${({ $padding }) => $padding ?? "0"};
  cursor: ${({ $cursor }) => $cursor ?? "inherit"};
`
