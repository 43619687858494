import React, { useContext, useEffect, useMemo, useRef, useState } from "react"

import styled from "styled-components"
import Link from "next/link"
import router from "next/router"
import { ScheduleDeliveryModal } from "./ScheduleDeliveryModal"
import { BREAKPOINT_QUERY, text, useModal } from "@openui"
import { OrderState } from "@models/order"
import { MerchantConfigContext } from "providers/MerchantConfigProvider"
import dayjs from "@helpers/dayjs"
import { useOrders } from "hooks/useOrders"
import { useActiveMenu } from "hooks/useActiveMenu"
import { useActiveStore } from "hooks/useActiveStore"
import { IdentityContext } from "providers/IdentityProvider"
import { pluralize } from "@helpers/pluralize"

enum AppBannerType {
  orderInProgress = "orderInProgress",
  storeClosed = "storeClosed",
  storeIsClosing = "storeIsClosing",
  storePaused = "storePaused",
  custom = "custom",
  // promo = "promo",
}

const HeaderBannerContainer = styled.div<{
  $backgroundColor?: string
  $textColor?: string
  $borderColor?: string
}>`
  display: flex;
  align-items: center;

  padding: 0px 40px;

  justify-content: space-between;
  height: 32px;

  ${text("Body/Regular")}

  background-color: ${({ theme, $backgroundColor }) =>
    $backgroundColor ?? theme.colors["Background/Brand"]};
  color: ${({ theme, $textColor }) =>
    $textColor ?? theme.colors["Content/Brand"]};

  border-bottom: solid 1px
    ${({ theme, $textColor, $borderColor }) =>
      $borderColor ?? $textColor ?? theme.colors["Content/Brand"]};

  a {
    color: inherit; /* blue colors for links too */
    text-decoration: inherit; /* no underline */
  }

  ${BREAKPOINT_QUERY["mobile"](`
    padding: 4px 12px;
  `)}
`

const HeaderBannerText = styled.div`
  ${text("Body/Bold")}
  font-size: 14px;

  text-overflow: ellipsis;
  word-wrap: break-word;

  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
`

const HeaderBannerCTA = styled.span`
  ${text("Body/Bold")}
  font-size: 14px;
  text-decoration: underline;
`

const STORE_CLOSING_THRESHOULD_MINUTE = 30
const STORE_CLOSING_TIMER_THRESHOULD_MINUTE = 10

export const HeaderBanner = () => {
  const merchantConfig = useContext(MerchantConfigContext)
  const { currentOrders } = useOrders()
  const { activeStore } = useActiveStore()
  const activeMenu = useActiveMenu()
  const scheduledOrderModalController = useModal()
  const identity = useContext(IdentityContext)

  const timerRef = useRef(0)
  const [timer, setTimer] = useState(0)

  const bannerConfig = useMemo(() => {
    const inProgressOrder = currentOrders.data?.pages
      .flatMap((page) => page.records)
      .find((order) => {
        if (!order) {
          return false
        }

        switch (order.orderState) {
          // before submission
          case OrderState.inCart:
          case OrderState.cartEmptied: {
            return false
          }

          // after submission
          case OrderState.created:
          case OrderState.processing:
          case OrderState.submitted:
          case OrderState.inPreparation:
          case OrderState.inTransit:
          case OrderState.delivered:
          case OrderState.pickupReady: {
            return true
          } // return intialized (usually alcohol cases)

          // completed order
          case OrderState.returnInitialized:
          case OrderState.completed:
          case OrderState.refunded:
          case OrderState.canceled:
          case OrderState.returned: {
            return false
          }

          // error
          case OrderState.unknown:
          case OrderState.error: {
            return false
          }
        }
      })

    if (inProgressOrder != null) {
      return {
        appBannerType: AppBannerType.orderInProgress,
        appBannerText: "You have an order in progress",
        appBannerCTA: "Track here",
        bannerColor: merchantConfig.theme.colors["Background/Brand"],
        bannerTextColor: merchantConfig.theme.colors["Content/Brand"],
        appBannerLink: `/orders?orderId=${inProgressOrder.id}`,
      }
    }

    const storeClosedConfig = {
      appBannerType: AppBannerType.orderInProgress,
      appBannerText: "We are currently closed",
      appBannerCTA: "Schedule order",
      bannerColor: merchantConfig.theme.colors["Background/Brand"],
      bannerTextColor: merchantConfig.theme.colors["Content/Brand"],
      appBannerClick: () => {
        if (!identity.identity) {
          router.push("/login")
        } else {
          scheduledOrderModalController.open()
        }
      },
    }

    if (!activeStore?.isOpen) {
      if (typeof window !== "undefined") {
        window.clearTimeout(timerRef.current)
      }

      return storeClosedConfig
    }

    if (activeMenu != null && activeStore != null) {
      const currentHours = activeMenu.data?.getClosestMenuHours(
        activeStore.timezone,
      )?.[0]

      if (currentHours != null) {
        const now = dayjs.tz(dayjs(), activeStore.timezone)

        const openHours = now
          .clone()
          .set("hour", currentHours.openTimeHour)
          .set("minute", currentHours.openTimeMinute)
        let closingHours = now
          .clone()
          .set("hour", currentHours.closeTimeHour)
          .set("minute", currentHours.closeTimeMinute)

        if (openHours.isAfter(closingHours))
          closingHours = closingHours.add(1, "day")

        const diff = closingHours.diff(now, "minute")

        if (diff <= STORE_CLOSING_THRESHOULD_MINUTE) {
          if (typeof window !== "undefined") {
            window.clearTimeout(timerRef.current)

            if (diff <= 0) {
              return storeClosedConfig
            }

            timerRef.current = window.setTimeout(() => {
              setTimer((oldTimer) => oldTimer + 1)
            }, 5000)
          }

          return {
            appBannerType: AppBannerType.storeIsClosing,
            appBannerText:
              diff <= STORE_CLOSING_TIMER_THRESHOULD_MINUTE
                ? `Store is closing in ${Math.max(diff, 1)} ${pluralize({ singular: "minute", plural: "minutes" }, Math.max(diff, 1))}.`
                : `Store is closing soon.`,
            bannerColor: merchantConfig.theme.colors["Background/Brand"],
            bannerTextColor: merchantConfig.theme.colors["Content/Brand"],
          }
        }
      }
    }

    if (typeof window !== "undefined") {
      window.clearTimeout(timerRef.current)
    }

    if (activeStore?.isOrderingPaused) {
      return {
        appBannerType: AppBannerType.storePaused,
        appBannerText: "Ordering is temporarily paused.",
        bannerColor: "#000000",
        bannerTextColor: "#FFFFFF",
        bannerBorderColor: "#000000",
      }
    }

    return null
  }, [
    currentOrders.data?.pages,
    merchantConfig.theme.colors,
    activeMenu,
    activeStore,
    timer,
  ])

  useEffect(() => {
    return () => {
      if (typeof window !== "undefined") {
        window.clearTimeout(timerRef.current)
      }
    }
  }, [])

  if (!bannerConfig) {
    return null
  }

  return (
    <>
      <HeaderBannerContainer
        $backgroundColor={bannerConfig.bannerColor}
        $textColor={bannerConfig.bannerTextColor}
        $borderColor={
          "bannerBorderColor" in bannerConfig
            ? bannerConfig.bannerBorderColor
            : undefined
        }
      >
        <HeaderBannerText>{bannerConfig.appBannerText}</HeaderBannerText>
        {bannerConfig.appBannerCTA ? (
          "appBannerLink" in bannerConfig ? (
            <Link href={bannerConfig.appBannerLink}>
              <HeaderBannerCTA>{bannerConfig.appBannerCTA}</HeaderBannerCTA>
            </Link>
          ) : (
            <HeaderBannerCTA onClick={bannerConfig.appBannerClick}>
              {bannerConfig.appBannerCTA}
            </HeaderBannerCTA>
          )
        ) : null}
      </HeaderBannerContainer>
      <ScheduleDeliveryModal controller={scheduledOrderModalController} />
    </>
  )
}
