import React from "react"
import { ReactNode, MouseEvent } from "react"
import styled, { css, useTheme } from "styled-components"
import { text, TextStyles, ThemeColors } from "../theme"

type TextAlignment = "left" | "center" | "right"

type TextWrap = "initial" | "nowrap"

type TextDecoration = "none" | "underline"

interface Props {
  children?: ReactNode | ReactNode[]
  color?: ThemeColors
  style?: TextStyles
  align?: TextAlignment
  decoration?: TextDecoration
  whitespace?: string
  onClick?: (e: MouseEvent) => void
}

export const Text = ({
  style = "Body/Regular",
  whitespace,
  children,
  color = "Content/Primary",
  align = "left",
  decoration = "none",
  onClick,
}: Props) => {
  const theme = useTheme()
  return (
    <Container
      onClick={onClick}
      $whitespace={whitespace}
      $textColor={theme.colors[color]}
      $textStyle={style}
      $align={align}
      $decoration={decoration}
    >
      {children}
    </Container>
  )
}

const Container = styled.div<{
  onClick?: (e: MouseEvent) => void
  $textColor: string
  $whitespace?: string
  $textStyle?: TextStyles
  $align: TextAlignment
  $wrap?: TextWrap
  $decoration: TextDecoration
}>`
  line-height: 1rem;
  ${({ $textColor, $decoration, onClick }) => css`
    color: ${$textColor};
    text-decoration: ${$decoration};
    cursor: ${Boolean(onClick) ? "pointer" : "inherit"};
  `}
  ${(p) => css`
    ${p.$textStyle !== undefined ? text(p.$textStyle) : ""}
    ${p.$whitespace ? `white-space: ${p.$whitespace};` : ""}
    text-align: ${p.$align};
  `}
`
