import { flatMap, map } from "lodash"
import { MenuCategory } from "./menuCategory"
import { DaysOfTheWeek, MenuHours } from "./menuHours"
import dayjs from "@helpers/dayjs"

const formatMenuHoursHour = (hour: number): string => {
  const formattedHour = hour - (hour > 12 ? 12 : 0)
  // Render 0 as 12 for hours
  return (formattedHour === 0 ? 12 : formattedHour).toString()
}

const formatMenuHoursMinute = (minute: number): string => {
  let minuteString = minute.toString()
  if (minuteString.length === 1) {
    minuteString = "0" + minuteString
  }
  return `:${minuteString}`
}

const formatMenuHoursAmPm = (hour: number): string => {
  return hour >= 12 ? "pm" : "am"
}

export const DaysOfTheWeekNames = {
  [DaysOfTheWeek.Monday]: "Monday",
  [DaysOfTheWeek.Tuesday]: "Tuesday",
  [DaysOfTheWeek.Wednesday]: "Wednesday",
  [DaysOfTheWeek.Thursday]: "Thursday",
  [DaysOfTheWeek.Friday]: "Friday",
  [DaysOfTheWeek.Saturday]: "Saturday",
  [DaysOfTheWeek.Sunday]: "Sunday",
}

// StoreMenu object
export class Menu {
  constructor(
    public id: number,
    public name: string,
    public merchantId: number,
    public storeId: number,
    public categories: Array<MenuCategory>,
    public hours: Array<MenuHours>,
    public isAvailable: boolean,
    public pickupWindows: Array<SchedulePickupWindow>,
  ) {}

  getClosestMenuHours(timezone: string) {
    const now = dayjs.tz(undefined, timezone)

    let currentHours: MenuHours | null = null
    let nextHours: MenuHours | null = null

    for (let i = 0; i < this.hours.length; i++) {
      const hours = this.hours[i]
      const openTime = now
        .clone()
        .set("hour", hours.openTimeHour)
        .set("minute", hours.openTimeMinute)
        .add(hours.dayOffset, "day")
      let closeTime = now
        .clone()
        .set("hour", hours.closeTimeHour)
        .set("minute", hours.closeTimeMinute)
        .add(hours.dayOffset, "day")

      if (openTime.hour() > closeTime.hour()) {
        closeTime = closeTime.add(1, "day")
      }

      if (now.isAfter(openTime) && now.isBefore(closeTime)) {
        currentHours = hours
        break
      } else if (now.isBefore(openTime)) {
        // the first store hours interval that starts after the current time
        // is the next store hour interval the store will be open
        nextHours ??= hours // sets if null
        break
      }
    }

    return [currentHours, nextHours]
  }

  formatMenuHours(
    timezone: string,
    includePrefix = true,
    prefixStyle: "short" | "long" = "long",
  ) {
    if (this.hours.length === 0) {
      return "Closed"
    }

    const prefixValue: Record<
      typeof prefixStyle,
      {
        open: string
        close: string
      }
    > = {
      short: {
        open: "Open ",
        close: "Closed ",
      },
      long: {
        open: "Open for orders  •  ",
        close: "Closed  •  ",
      },
    }

    const now = dayjs.tz(undefined, timezone)
    // This is in the stores timezones
    const [currentHours, nextHours] = this.getClosestMenuHours(timezone)

    // the current time is within a store hours interval so it is open
    if (currentHours != null) {
      const openHour = formatMenuHoursHour(currentHours.openTimeHour)
      const openMinute = formatMenuHoursMinute(currentHours.openTimeMinute)
      const openAmPm = formatMenuHoursAmPm(currentHours.openTimeHour)
      const closeHour = formatMenuHoursHour(currentHours.closeTimeHour)
      const closeMinute = formatMenuHoursMinute(currentHours.closeTimeMinute)
      const closeAmPm = formatMenuHoursAmPm(currentHours.closeTimeHour)
      return `${includePrefix ? prefixValue[prefixStyle]["open"] : ""}${openHour}${openMinute}${openAmPm} - ${closeHour}${closeMinute}${closeAmPm}`
    }
    // store is closed and there is a future open time
    if (nextHours != null) {
      const nextHoursDateTime = now.add(nextHours.dayOffset, "day")
      const openHour = formatMenuHoursHour(nextHours.openTimeHour)
      const openMinute = formatMenuHoursMinute(nextHours.openTimeMinute)
      const openAmPm = formatMenuHoursAmPm(nextHours.openTimeHour)
      if (nextHours.dayOffset == 0) {
        return `${includePrefix ? prefixValue[prefixStyle]["close"] : ""}\nOpens today at ${openHour}${openMinute}${openAmPm}`
      } else if (nextHours.dayOffset == 1) {
        return `${includePrefix ? prefixValue[prefixStyle]["close"] : ""}\nOpens tomorrow at ${openHour}${openMinute}${openAmPm}`
      } else {
        return `${includePrefix ? prefixValue[prefixStyle]["close"] : ""}\nOpens ${nextHoursDateTime.month() + 1}/${nextHoursDateTime.date()} at ${openHour}${openMinute}${openAmPm}`
      }
    }

    return "Closed"
  }

  formatAllMenuHours(timezone: string) {
    const times: Record<DaysOfTheWeek, Array<string>> = {
      [DaysOfTheWeek.Monday]: [],
      [DaysOfTheWeek.Tuesday]: [],
      [DaysOfTheWeek.Wednesday]: [],
      [DaysOfTheWeek.Thursday]: [],
      [DaysOfTheWeek.Friday]: [],
      [DaysOfTheWeek.Saturday]: [],
      [DaysOfTheWeek.Sunday]: [],
    }

    // TODO: Refactor
    const today = dayjs.tz(undefined, timezone).day()
    let todayKey = 0

    switch (today) {
      // Sunday
      case 0:
        todayKey = DaysOfTheWeek.Sunday
        break
      // Monday
      case 1:
        todayKey = DaysOfTheWeek.Monday
        break
      // Tuesday
      case 2:
        todayKey = DaysOfTheWeek.Tuesday
        break
      // Wednesday
      case 3:
        todayKey = DaysOfTheWeek.Wednesday
        break
      // Thursday
      case 4:
        todayKey = DaysOfTheWeek.Thursday
        break
      // Friday
      case 5:
        todayKey = DaysOfTheWeek.Friday
        break
      // Saturday
      case 6:
        todayKey = DaysOfTheWeek.Saturday
        break
    }

    for (let i = 0; i < this.hours.length; i++) {
      const hours = this.hours[i]
      const key = ((todayKey + hours.dayOffset) % 7) as DaysOfTheWeek

      times[key] ??= []

      const openHour = formatMenuHoursHour(hours.openTimeHour)
      const openMinute = formatMenuHoursMinute(hours.openTimeMinute)
      const openAmPm = formatMenuHoursAmPm(hours.openTimeHour)
      const closeHour = formatMenuHoursHour(hours.closeTimeHour)
      const closeMinute = formatMenuHoursMinute(hours.closeTimeMinute)
      const closeAmPm = formatMenuHoursAmPm(hours.closeTimeHour)

      times[key].push(
        `${openHour}${openMinute}${openAmPm} - ${closeHour}${closeMinute}${closeAmPm}`,
      )
    }

    for (let i = DaysOfTheWeek.Monday; i <= DaysOfTheWeek.Sunday; i++) {
      if (!times[i].length) {
        times[i].push("Closed")
      }
    }

    return times
  }

  static fromDynamic(data: { [key: string]: any }) {
    const menuCategories: Array<MenuCategory> = map(
      data["menu_categories"],
      MenuCategory.fromDynamic,
    ).sort((a, b) => a.sortOrder - b.sortOrder)
    const menuHours: Array<MenuHours> = flatMap(
      data["hours"],
      (menuHourByDayJson) => flatMap(menuHourByDayJson, MenuHours.fromDynamic),
    )
    const pickupWindows: Array<SchedulePickupWindow> = map(
      data["scheduled_pickup_windows"],
      SchedulePickupWindow.fromDynamic,
    )

    return new Menu(
      data["id"],
      data["name"],
      data["merchant_id"],
      data["store_id"],
      menuCategories,
      menuHours,
      data["is_available"],
      pickupWindows,
    )
  }
}

export class TimeWindow {
  constructor(
    public startTime: string = "00:00:00",
    public endTime: string = "00:00:00",
    public isASAP: boolean = false,
  ) {}

  static fromDynamic(data: { [key: string]: any }) {
    return new TimeWindow(data["start_time"], data["end_time"])
  }
}

export class SchedulePickupWindow {
  constructor(
    public date: dayjs.Dayjs,
    public windows: Array<TimeWindow>,
  ) {}

  static fromDynamic(data: { [key: string]: any }) {
    const windows: Array<TimeWindow> = map(
      data["windows"],
      TimeWindow.fromDynamic,
    )

    return new SchedulePickupWindow(dayjs(data["date"]), windows)
  }
}
