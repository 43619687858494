import { BREAKPOINT_QUERY } from "openui/media"
import React from "react"
import styled, { css } from "styled-components"

export type Direction =
  | "top"
  | "right"
  | "bottom"
  | "left"
  | "top-right"
  | "top-left"
  | "bottom-right"
  | "bottom-left"

interface TooltipProps {
  content: React.ReactNode
  direction?: Direction
  children: React.ReactNode
}

const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
`

const TooltipText = styled.div<{ direction: Direction }>`
  visibility: hidden;
  opacity: 0;
  background-color: ${({ theme }) => theme.colors["Background/Primary"]};
  color: ${({ theme }) => theme.colors["Content/Primary"]};
  border: solid 1px ${({ theme }) => theme.colors["Border/Primary"]};

  text-align: center;
  border-radius: 8px;
  padding: 8px;
  position: absolute;
  z-index: 100;
  white-space: normal;
  font-size: 14px;
  line-height: 1.4;
  max-width: 600px;
  min-width: 240px;

  transition:
    opacity 400ms ease,
    visibility 400ms ease;

  /* Pseudo-element for the arrow */
  &::before,
  &::after {
    content: "";
    position: absolute;
    border-width: 6px;
    border-style: solid;
    border-color: transparent;
  }

  &::before {
    border-width: 5px;
    z-index: 1;
  }

  ${BREAKPOINT_QUERY["mobile"](`
    max-width: 270px;
    width: 270px;
    white-space: pre-wrap;
  `)}

  ${({ direction }) => directionStyles[direction]}
`

const TooltipWrapper = styled.div`
  &:hover ${TooltipText} {
    visibility: visible;
    opacity: 1;
  }
`

const directionStyles = {
  top: css`
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    &::before,
    &::after {
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-top-color: ${({ theme }) => theme.colors["Content/Primary"]};
    }

    &::before {
      border-top-color: ${({ theme }) => theme.colors["Background/Brand"]};
      margin-bottom: 2.5px;
    }
  `,
  right: css`
    top: 50%;
    left: 125%;
    transform: translateY(-50%);
    &::before,
    &::after {
      top: 50%;
      left: 12px;
      transform: translateY(-50%);
      border-right-color: ${({ theme }) => theme.colors["Content/Primary"]};
    }

    &::before {
      border-right-color: ${({ theme }) => theme.colors["Background/Brand"]};
      margin-left: 2.5px;
    }
  `,
  bottom: css`
    top: 125%;
    left: 50%;
    transform: translateX(-50%);
    &::before,
    &::after {
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-bottom-color: ${({ theme }) => theme.colors["Content/Primary"]};
    }

    &::before {
      border-bottom-color: ${({ theme }) => theme.colors["Background/Brand"]};
      margin-top: 2.5px;
    }
  `,
  left: css`
    top: 50%;
    right: 125%;
    transform: translateY(-50%);
    &::before,
    &::after {
      top: 50%;
      right: -12px;
      transform: translateY(-50%);
      border-left-color: ${({ theme }) => theme.colors["Content/Primary"]};
    }

    &::before {
      border-left-color: ${({ theme }) => theme.colors["Background/Brand"]};
      margin-right: 2.5px;
    }
  `,

  "top-left": css`
    top: -10px;
    right: 125%;
    transform: translateY(0%);

    &::before,
    &::after {
      top: 10px;
      right: -12px;
      transform: translateY(-0%);
      border-left-color: ${({ theme }) => theme.colors["Content/Primary"]};
    }

    &::before {
      border-left-color: ${({ theme }) => theme.colors["Background/Brand"]};
      margin-right: 2.5px;
      margin-top: 1px;
    }
  `,
  "top-right": css`
    top: -10px;
    left: 125%;
    transform: translateY(0%);

    &::before,
    &::after {
      top: 10px;
      left: -12px;
      transform: translateY(-0%);
      border-right-color: ${({ theme }) => theme.colors["Content/Primary"]};
    }

    &::before {
      border-right-color: ${({ theme }) => theme.colors["Background/Brand"]};
      margin-left: 2.5px;
      margin-top: 1px;
    }
  `,
  "bottom-left": css`
    bottom: -10px;
    right: 125%;
    transform: translateY(0%);

    &::before,
    &::after {
      bottom: 10px;
      right: -12px;
      transform: translateY(-0%);
      border-left-color: ${({ theme }) => theme.colors["Content/Primary"]};
    }

    &::before {
      border-left-color: ${({ theme }) => theme.colors["Background/Brand"]};
      margin-right: 2.5px;
      margin-bottom: 1px;
    }
  `,
  "bottom-right": css`
    bottom: -10px;
    left: 125%;
    transform: translateY(0%);

    &::before,
    &::after {
      bottom: 10px;
      left: -12px;
      transform: translateY(-0%);
      border-right-color: ${({ theme }) => theme.colors["Content/Primary"]};
    }

    &::before {
      border-right-color: ${({ theme }) => theme.colors["Background/Brand"]};
      margin-left: 2.5px;
      margin-bottom: 1px;
    }
  `,
}

export const Tooltip: React.FC<TooltipProps> = ({
  content,
  direction = "top",
  children,
}) => (
  <TooltipContainer>
    <TooltipWrapper>
      {children}
      <TooltipText direction={direction}>{content}</TooltipText>
    </TooltipWrapper>
  </TooltipContainer>
)
