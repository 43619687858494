import styled, { useTheme } from "styled-components"
import { text } from "../theme"
import { useEffect, useState } from "react"
import { Icon, IconKey } from "./Icon"

interface Props {
  quantity: number
  onChange: (quantity: number) => void
  min?: number
  max?: number
  height?: string
  allowDelete?: boolean
}

export const InputQuantity = ({
  quantity,
  onChange,
  min,
  max,
  height = "36px",
  allowDelete = false,
}: Props) => {
  const theme = useTheme()
  const [isEditing, setIsEditing] = useState(false)
  const [editedQuantity, setEditedQuantity] = useState(quantity.toString())
  const handleSetValue = (value: number) => {
    let clampedValue = value
    if (min !== undefined) clampedValue = Math.max(min, clampedValue)
    if (max !== undefined) clampedValue = Math.min(max, clampedValue)
    onChange(clampedValue)
    navigator?.vibrate(50)
  }
  const handleApplyEditedQuantity = () => {
    const asInt = parseInt(editedQuantity)
    if (!isNaN(asInt)) {
      handleSetValue(asInt)
    }
    setIsEditing(false)
  }
  useEffect(() => {
    setEditedQuantity(quantity.toString())
  }, [quantity])
  return (
    <Container height={height} onClick={(e) => e.stopPropagation()}>
      <Section
        onClick={(e) => {
          e.stopPropagation()
          handleSetValue(quantity - 1)
        }}
      >
        <Icon
          iconKey={
            quantity === 1 && allowDelete
              ? IconKey.CustomerDelete
              : IconKey.Decrement
          }
          size={12}
          color={
            min !== undefined && quantity <= min
              ? theme.colors["Content/Secondary"]
              : theme.colors["Content/Primary"]
          }
        />
      </Section>
      <Section
        onClick={(e) => {
          e.stopPropagation()
          setIsEditing(true)
        }}
      >
        {!isEditing && quantity}
        {isEditing && (
          <Input
            autoFocus
            value={editedQuantity}
            onChange={(e) => setEditedQuantity(e.target.value)}
            onBlur={handleApplyEditedQuantity}
            onSubmit={handleApplyEditedQuantity}
          />
        )}
      </Section>
      <Section
        onClick={(e) => {
          e.stopPropagation()
          handleSetValue(quantity + 1)
        }}
      >
        <Icon
          iconKey={IconKey.Increment}
          size={12}
          color={
            max !== undefined && quantity >= max
              ? theme.colors["Content/Secondary"]
              : theme.colors["Content/Primary"]
          }
        />
      </Section>
    </Container>
  )
}

const Container = styled.div<{ height: string }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${text("Body/Regular")}
  border: solid 1px ${({ theme }) => theme.colors["Border/Primary"]};
  border-radius: ${({ theme }) => theme.borderRadius};
  min-width: 96px;
  max-width: 96px;
  padding: 0 6px;
  height: ${({ height }) => height};
`

const Section = styled.div`
  min-width: 33%;
  max-width: 33%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const Input = styled.input`
  height: 100%;
  border: solid 1px ${({ theme }) => theme.colors["Border/Primary"]};
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: 4px;
  width: 100%;
  ${text("Body/Regular")}
  text-align: center;
`
