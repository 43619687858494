import React from "react"
import styled, { css } from "styled-components"
import { Spacer } from "./Spacer"
import { Text } from "./Text"

interface Props {
  value: boolean
  onChange: (value: boolean) => void
  label?: string
}

export const InputRadio = ({ value, onChange, label }: Props) => {
  return (
    <Container onClick={() => onChange(!value)}>
      <Box>
        <Checkmark active={value} />
      </Box>
      {label !== undefined && (
        <>
          <Spacer size={2} $vertical />
          <Text style="Body/Regular" color="Content/Primary">
            {label}
          </Text>
        </>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const Box = styled.div`
  width: 20px;
  height: 20px;
  border: solid 1.5px ${({ theme }) => theme.colors["Content/Primary"]};
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Checkmark = styled.div<{ active: boolean }>`
  width: 12px;
  height: 12px;
  border-radius: 6px;
  transition: all 0.2s;
  ${({ active, theme }) =>
    active
      ? css`
          background-color: ${theme.colors["Content/Primary"]};
        `
      : ""}
`
