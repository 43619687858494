import React from "react"
import { useContext } from "react"
import styled from "styled-components"
import { Text } from "openui/components/Text"
import { Spacer } from "@openui"
import { MerchantConfigContext } from "providers/MerchantConfigProvider"

interface Props {
  cardLabel: string
  cardValue: string
}

/* Used for Gift card and Loyalty card components */

export const StoreBrandCard = ({ cardLabel, cardValue }: Props) => {
  const merchantConfig = useContext(MerchantConfigContext)
  return (
    <StoreCard>
      <StoreBrandCardContainer>
        <StoreBrandCardLogo
          src={merchantConfig.config?.logoUrl}
          alt={merchantConfig.config?.displayName ?? "Store"}
        />
      </StoreBrandCardContainer>
      <Spacer size={4} />
      <StoreBrandCardText>
        <Text style="DisplayHeading/XLarge" align="right" color="Content/Brand">
          {cardValue}
        </Text>
        <Spacer size={4} />
        <Text style="Body/Regular" align="right" color="Content/Brand">
          {cardLabel}
        </Text>
      </StoreBrandCardText>
    </StoreCard>
  )
}

const StoreCard = styled.div`
  display: flex;
  flex-direction: column;

  aspect-ratio: 4/2.2;
  width: 100%;

  background-color: ${({ theme }) => theme.colors["Background/Brand"]};
  padding: 32px;
  border-radius: 12px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
`
const StoreBrandCardContainer = styled.div`
  max-width: 60%;
  height: fit-content;
  width: 100%;
  max-height: 80px;
  display: inline-flex;

  align-items: center;
  justify-content: flex-start;
`

const StoreBrandCardLogo = styled.img`
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;

  object-fit: contain;
`

const StoreBrandCardText = styled.div`
  margin-top: auto;
`
