import React, { useContext, useEffect, useState } from "react"
import { ReactNode } from "react"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useRouter } from "next/router"
import { shutdown, boot } from "@intercom/messenger-js-sdk"
import posthog from "posthog-js"
import { first, isArray } from "lodash"
import { MerchantConfigContext } from "./MerchantConfigProvider"
import { User } from "@models/user"
import { Customer } from "@models/customer"
import apiClient from "@api/client"
import { QUERY_KEYS } from "@api/constants"
import { INTERCOM_APP_ID } from "hooks/useIntercom"

export interface Identity {
  user: User
  customer: Customer
}

export interface IdentityContext {
  isFetched?: boolean
  identity?: Identity | null
  activeStoreId?: number | null
  setAnonymousUserActiveStore: React.Dispatch<
    React.SetStateAction<number | null>
  >
  login: (token: string) => void
  logout: () => void
  refresh: () => void
}

export const IdentityContext = React.createContext<IdentityContext>({
  identity: null,
  activeStoreId: null,
  setAnonymousUserActiveStore: () => null,
  login: () => {},
  logout: () => {},
  refresh: () => {},
})

interface Props {
  token?: string | null
  children: ReactNode | ReactNode[]
}

export const getIdentityQueryConfig = (
  merchantId?: number | null,
  token?: string | null,
) => {
  const configApiUrl = `identify/${merchantId}`
  return {
    enabled: merchantId !== null && merchantId !== undefined,
    queryKey: [QUERY_KEYS.GET_LOGIN, configApiUrl],
    queryFn: async () => {
      if (!token && !apiClient.hasToken) {
        return null
      }

      const response = await apiClient.get<{ [key: string]: unknown }>(
        configApiUrl,
        token,
      )
      if (response && response?.body !== null) {
        return response.body
      }
      return null
    },
    staleTime: Infinity,
    select: (response: { [key: string]: unknown } | null): Identity | null => {
      return !!response
        ? {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            user: User.fromDynamic(response.user as { [key: string]: any }),
            customer: Customer.fromDynamic(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              response.customer as { [key: string]: any },
            ),
          }
        : null
    },
  }
}

export const IdentityProvider = ({ children }: Props) => {
  const router = useRouter()
  const queryClient = useQueryClient()

  const [anonymousUserActiveStore, setAnonymousUserActiveStore] = useState<
    number | null
  >(() => {
    const storeQuery = router.query["store"]
    const storeIdFromQuery = isArray(storeQuery)
      ? first(storeQuery)
      : storeQuery
    const parsedStore = parseInt(storeIdFromQuery ?? "", 10)

    if (isNaN(parsedStore)) {
      return null
    }

    return parsedStore
  })

  const merchantConfig = useContext(MerchantConfigContext)

  const identityQuery = useQuery({
    ...getIdentityQueryConfig(merchantConfig.config?.merchantId),
  })

  useEffect(() => {
    // In case the cookie disappears we can't login on the server side so we login on the client side
    if (typeof window === "undefined") return
    if (!identityQuery.data && apiClient.hasToken) {
      queryClient.resetQueries({ queryKey: [QUERY_KEYS.GET_LOGIN] })
    }
  }, [])

  useEffect(() => {
    if (router.pathname !== "/onboard" && router.pathname !== "/gift-cards") {
      if (
        identityQuery.data?.user &&
        (!identityQuery.data?.user.firstName ||
          !identityQuery.data?.user.lastName)
      ) {
        router.push("/onboard")
      } else if (
        identityQuery.data?.customer &&
        !identityQuery.data.customer.lastSelectedStoreId
      ) {
        router.push("/onboard?step=location")
      }
    }

    if (
      identityQuery.data?.user &&
      identityQuery.data.user.firstName &&
      !posthog._isIdentified()
    ) {
      const { user } = identityQuery.data
      posthog.identify(`${user.id}`, {
        email: user?.email ?? "",
        phone: user?.phone ?? "",
        firstName: user?.firstName ?? "",
        lastName: user?.lastName ?? "",
        platform: "web",
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identityQuery.data])

  const refresh = () => {
    identityQuery.refetch()
  }

  const login = (token: string) => {
    if (typeof window !== "undefined") {
      apiClient.setToken(token)
    }
    queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GET_WALLETS] })
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.GET_CREDIT],
    })
    queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GET_CART] })
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.GET_ORDERS],
    })
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.GET_MERCHANT_LOYALTY_PROGRAM],
    })
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.GET_LOYALTY_TRANSACTIONS],
    })
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.GET_LOYALTY_ACCOUNT],
    })

    refresh()
  }

  const logout = () => {
    posthog.reset()
    shutdown()
    boot({ app_id: INTERCOM_APP_ID })
    apiClient.setToken(null)

    queryClient.resetQueries(
      { queryKey: [QUERY_KEYS.GET_LOGIN] },
      {
        cancelRefetch: false,
      },
    )
    queryClient.resetQueries(
      { queryKey: [QUERY_KEYS.GET_WALLETS] },
      {
        cancelRefetch: false,
      },
    )
    queryClient.resetQueries(
      {
        queryKey: [QUERY_KEYS.GET_CREDIT],
      },
      {
        cancelRefetch: false,
      },
    )
    queryClient.resetQueries(
      { queryKey: [QUERY_KEYS.GET_CART] },
      {
        cancelRefetch: false,
      },
    )
    queryClient.resetQueries(
      {
        queryKey: [QUERY_KEYS.GET_ORDERS],
      },
      {
        cancelRefetch: false,
      },
    )
    queryClient.resetQueries(
      {
        queryKey: [QUERY_KEYS.GET_MERCHANT_LOYALTY_PROGRAM],
      },
      {
        cancelRefetch: false,
      },
    )
    queryClient.resetQueries(
      {
        queryKey: [QUERY_KEYS.GET_LOYALTY_TRANSACTIONS],
      },
      {
        cancelRefetch: false,
      },
    )
    queryClient.resetQueries(
      {
        queryKey: [QUERY_KEYS.GET_LOYALTY_ACCOUNT],
      },
      {
        cancelRefetch: false,
      },
    )
    queryClient.resetQueries(
      {
        queryKey: [QUERY_KEYS.GET_CURRENT_ORDERS],
      },
      {
        cancelRefetch: false,
      },
    )
    queryClient.resetQueries(
      {
        queryKey: [QUERY_KEYS.GET_COMPLETED_ORDERS],
      },
      {
        cancelRefetch: false,
      },
    )
    queryClient.resetQueries(
      {
        queryKey: [QUERY_KEYS.GET_GIFT_CARD_ORDERS],
      },
      {
        cancelRefetch: false,
      },
    )

    setAnonymousUserActiveStore(null)

    refresh()
  }

  return (
    <IdentityContext.Provider
      value={{
        isFetched:
          identityQuery.isFetched &&
          !(!identityQuery.data && apiClient.hasToken),
        identity: identityQuery.data,
        activeStoreId:
          identityQuery.data?.customer.lastSelectedStoreId ??
          anonymousUserActiveStore ??
          null,
        setAnonymousUserActiveStore,
        login,
        logout,
        refresh,
      }}
    >
      {children}
    </IdentityContext.Provider>
  )
}

export const useRequestVerificationCode = () => {
  const merchantConfig = useContext(MerchantConfigContext)
  return useMutation({
    mutationFn: ({ phone }: { phone: string }) =>
      apiClient.post(`login/`, {
        phone,
        merchant_id: merchantConfig.config?.merchantId,
      }),
  })
}
