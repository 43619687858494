export const SCREEN_SIZE = {
  mobile: 600,
  tablet: 1024,
  desktop: 1024,
}

export const BREAKPOINT_QUERY = {
  mobile: (query: string) =>
    `@media (max-width: ${SCREEN_SIZE.mobile}px) {${query}}`,
  tablet: (query: string) =>
    `@media (max-width: ${SCREEN_SIZE.tablet}px) {${query}}`,
  desktop: (query: string) =>
    `@media (min-width: ${SCREEN_SIZE.desktop}px) {${query}}`,
}
