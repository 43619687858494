export class OrderTotal {
  constructor(
    public subtotal: number | null = null,
    public discount: number | null = null,
    public loyaltyDiscount: number,
    public tax: number | null = null,
    public serviceFee: number | null = null,
    public storeTip: number | null = null,
    public courierTip: number | null = null,
    public deliveryFee: number | null = null,
    public deliveryFeeSubsidy: number | null = null,
    public rewardsUsed: number | null = null,
    public refunds: number,
    public total: number | null = null,
    public creditsApplied: number
  ) {}

  static fromDynamic(data: { [key: string]: any }) {
    return new OrderTotal(
      data["subtotal"],
      data["discount"],
      data["loyalty_discount"],
      data["tax"],
      data["service_fee"],
      data["store_tip"],
      data["courier_tip"],
      data["delivery_fee"],
      data["delivery_fee_subsidy"],
      data["rewards_used"],
      data["refunds"],
      data["total"],
      data["credits_applied"]
    );
  }
}
