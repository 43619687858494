import { useQuery } from "@tanstack/react-query"
import apiClient from "@api/client"
import { QUERY_KEYS } from "@api/constants"
import { SchedulePickupWindow } from "@models/menu"

export const useFetchScheduleWindow = ({
  storeId,
  storeMenuId,
  cartId,
}: {
  storeId: number
  storeMenuId: number
  cartId?: number
}) => {
  return useQuery({
    queryKey: [QUERY_KEYS.FETCH_SCHEDULE_WINDOW, storeId, storeMenuId, cartId],
    enabled: ![storeId, storeMenuId, cartId].includes(-1),
    queryFn: async () => {
      const response = await apiClient.get<{
        windows: Array<SchedulePickupWindow>
      }>(
        `stores/${storeId}/menus/${storeMenuId}/schedule-windows${cartId !== undefined ? `?cart_id=${cartId}` : ""}`,
      )

      return (
        response?.body?.windows?.map(SchedulePickupWindow.fromDynamic) ?? []
      )
    },
  })
}
