import styled, { useTheme } from "styled-components"
import { text } from "../theme/text"
import { ButtonHTMLAttributes, ReactNode } from "react"
import { Loading } from "./Loading"
import { ThemeColors } from "openui/theme"

interface Props
  extends Partial<Omit<ButtonHTMLAttributes<HTMLButtonElement>, "children">> {
  children: ReactNode | ReactNode[]
  width?: string
  height?: string
  onClick?: () => void
  isDisabled?: boolean
  isLoading?: boolean
  color?: ThemeColors
  contentColor?: ThemeColors
}

export const Button = ({
  children,
  width = "fit-content",
  height = "fit-content",
  onClick,
  isDisabled = false,
  isLoading = false,
  color = "Background/Brand",
  contentColor = "Content/Brand",
  ...buttonProps
}: Props) => {
  const theme = useTheme()
  return (
    <Container
      {...buttonProps}
      onClick={
        isDisabled || isLoading
          ? () => {}
          : () => {
              onClick?.()
              navigator?.vibrate(50)
            }
      }
      $width={width}
      $height={height}
      $disabled={isDisabled}
      $color={color}
      $contentColor={contentColor}
    >
      {isLoading ? (
        <Loading size={20} color={theme.colors[contentColor]} />
      ) : (
        children
      )}
    </Container>
  )
}

const Container = styled.button<{
  $width: string
  $height: string
  $disabled: boolean
  $color: ThemeColors
  $contentColor: ThemeColors
}>`
  width: ${({ $width }) => $width};
  min-height: ${({ $height }) => $height};
  ${({ $disabled }) => ($disabled ? "opacity: 0.7;" : "")};
  padding: 12px 20px;
  background-color: ${({ theme, $color }) => theme.colors[$color]};
  color: ${({ theme, $contentColor }) => theme.colors[$contentColor]};
  ${text("Body/SemiBold")}
  border: solid 1px ${({ theme, $contentColor }) =>
    theme.colors[$contentColor]};
  border-radius: ${({ theme }) => theme.borderRadius};
  display: flex;
  justify-content: center;
  transition: all 0.2s;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  &:hover {
    filter: brightness(1.1);
  }
`
