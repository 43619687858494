export enum QUERY_KEYS {
  GET_LOGIN = "GET_LOGIN",
  SAVED_ADDRESSES = "SAVED_ADDRESSES",
  SEARCH_ADDRESSES = "SEARCH_ADDRESSES",
  GET_ADDRESS = "GET_ADDRESS",
  GET_DEFAULT_DELIVERY_ADDRESS = "GET_DEFAULT_DELIVERY_ADDRESS",
  GET_MERCHANT_CONFIG = "GET_MERCHANT_CONFIG",
  GET_LOCATION = "GET_LOCATION",
  GET_LAT_LNG_FROM_ADDRESS = "GET_LAT_LNG_FROM_ADDRESS",
  GET_ADDRESS_FROM_LAT_LNG = "GET_ADDRESS_FROM_LAT_LNG",
  FETCH_SCHEDULE_WINDOW = "FETCH_SCHEDULE_WINDOW",
  GET_NOTIFICATIONS = "GET_NOTIFICATIONS",
  GET_ACTIVE_MENU = "GET_ACTIVE_MENU",
  GET_POPULAR_MENU_ITEMS = "GET_POPULAR_MENU_ITEMS",
  GET_NEW_CUSTOMER_PROMO = "GET_NEW_CUSTOMER_PROMO",
  GET_CART = "GET_CART",
  GET_ORDERS = "GET_ORDERS",
  GET_ORDER = "GET_ORDER",
  GET_STORES = "GET_STORES",
  GET_CREDIT = "GET_CREDIT",
  GET_GIFT_CARDS = "GET_GIFT_CARDS",
  GET_WALLETS = "GET_WALLETS",
  GET_MERCHANT_LOYALTY_PROGRAM = "GET_MERCHANT_LOYALTY_PROGRAM",
  GET_LOYALTY_TRANSACTIONS = "GET_LOYALTY_TRANSACTIONS",
  SETUP_INTENT = "SETUP_INTENT",
  SYNC_CARD_TO_OPEN = "SYNC_CARD_TO_OPEN",
  GET_MERCHANT_FEE_SETTINGS = "GET_MERCHANT_FEE_SETTINGS",
  GET_LOYALTY_ACCOUNT = "GET_LOYALTY_ACCOUNT",
  GET_CURRENT_ORDERS = "GET_CURRENT_ORDERS",
  GET_COMPLETED_ORDERS = "GET_COMPLETED_ORDERS",
  GET_GIFT_CARD_ORDERS = "GET_GIFT_CARD_ORDERS",
}
