import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useContext, useEffect, useRef, useState } from "react"
import { useActiveStore } from "./useActiveStore"
import apiClient from "@api/client"
import { QUERY_KEYS } from "@api/constants"
import { FulfillmentType, Order, OrderScheduleType } from "@models/order"
import { IdentityContext } from "providers/IdentityProvider"
import { ONE_MINUTE } from "@constants/time"

const scheduleTypeToString = (type: OrderScheduleType): string => {
  switch (type) {
    case OrderScheduleType.asap:
      return "ASAP"
    case OrderScheduleType.fixedTime:
      return "FIXED_TIME"
  }
}

export const useCart = () => {
  const identity = useContext(IdentityContext)
  const queryClient = useQueryClient()
  const { activeStore } = useActiveStore()

  const inFlightRequestCountRef = useRef(0)
  const [cart, setCart] = useState<Order>()

  const order = useQuery({
    queryKey: [QUERY_KEYS.GET_CART, activeStore?.id],
    staleTime: apiClient.hasToken ? ONE_MINUTE : undefined,
    refetchOnWindowFocus: "always",
    queryFn: async () => {
      if (!apiClient.hasToken) {
        return null
      }

      const res = await apiClient.get(`stores/${activeStore?.id}/cart/`)
      if (res) return Order.fromDynamic(res.body as { [key: string]: never })
      throw Error()
    },
    enabled: Boolean(identity.identity && !!activeStore),
  })

  useEffect(() => {
    if (order.data && inFlightRequestCountRef.current <= 1) {
      setCart(order.data)
    }
    inFlightRequestCountRef.current = Math.max(
      inFlightRequestCountRef.current - 1,
      0,
    )
  }, [order.data])

  const updateOrderItemQuantity = useMutation<
    unknown,
    Error,
    { orderItemId: number; quantity: number },
    unknown
  >({
    mutationFn: async ({ orderItemId, quantity }) => {
      await apiClient.put(
        `stores/${activeStore?.id}/cart/update-item-quantity/`,
        {
          orderItemId,
          quantity,
        },
      )
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GET_CART] })
    },
  })

  const setQuantity = (orderItemId: number, quantity: number) => {
    inFlightRequestCountRef.current = inFlightRequestCountRef.current + 1
    setCart((prev) => {
      if (!prev) return prev
      const newCart = { ...prev }
      const i = newCart.orderItems?.findIndex((oi) => oi.id === orderItemId)
      if (i === -1) return prev
      if (quantity === 0) newCart.orderItems.splice(i, 1)
      else newCart.orderItems[i].quantity = quantity
      return newCart
    })
    updateOrderItemQuantity.mutate(
      { orderItemId, quantity },
      {
        onSuccess: () => {
          identity.refresh()
        },
      },
    )
  }

  const updatePackagingPreferences = useMutation<
    unknown,
    Error,
    boolean,
    unknown
  >({
    mutationFn: async (includeUtensils: boolean) => {
      await apiClient.post(
        `stores/${activeStore?.id}/cart/edit-packaging-preferences/`,
        {
          includeUtensils: includeUtensils,
        },
      )
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GET_CART] })
    },
  })

  const setFulfillmentType = useMutation<
    unknown,
    Error,
    {
      fulfillmentType: FulfillmentType
      isCurbside: boolean
      curbsidePickupVehicleMakeModel: string
      curbsidePickupVehicleColor: string
      curbsidePickupVehicleNotes: string
    },
    unknown
  >({
    mutationFn: async ({
      fulfillmentType,
      isCurbside,
      curbsidePickupVehicleMakeModel,
      curbsidePickupVehicleColor,
      curbsidePickupVehicleNotes,
    }) => {
      const fullfillmentTypeString =
        fulfillmentType == FulfillmentType.delivery ? "DELIVERY" : "PICKUP"
      const res = await apiClient.put(
        `stores/${activeStore?.id}/cart/edit-delivery-type/`,
        {
          fulfillmentType: fullfillmentTypeString,
          curbside_pickup_vehicle_make_model: curbsidePickupVehicleMakeModel,
          curbside_pickup_vehicle_color: curbsidePickupVehicleColor,
          curbside_pickup_vehicle_notes: curbsidePickupVehicleNotes,
          is_curbside: isCurbside,
        },
      )
      if (res?.statusCode != 200) {
        const bodyAsErrorMessage = (res?.body ?? {}) as { message?: string }
        if (bodyAsErrorMessage.message) {
          throw Error(
            bodyAsErrorMessage.message != ""
              ? bodyAsErrorMessage.message
              : "Delivery is not available to selected address.",
          )
        } else {
          // throw a more generic error
          throw Error("Delivery is not available to selected address.")
        }
      }
      return res.statusCode == 200
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GET_CART] })
    },
  })

  const setStoreTip = useMutation<unknown, Error, number, unknown>({
    mutationFn: async (tipCents: number) => {
      await apiClient.put(`stores/${activeStore?.id}/cart/edit-store-tip/`, {
        storeTip: tipCents,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GET_CART] })
    },
  })

  const setCourierTip = useMutation<unknown, Error, number, unknown>({
    mutationFn: async (tipCents: number) => {
      await apiClient.put(`stores/${activeStore?.id}/cart/edit-courier-tip/`, {
        courierTip: tipCents,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GET_CART] })
    },
  })

  const submitOrder = useMutation({
    mutationFn: async (paymentMethodId: string | null) => {
      const response = await apiClient.post<
        { [keys: string]: any },
        {
          stripe_payment_method_id: string | null
        }
      >(`stores/${activeStore?.id}/cart/submit/`, {
        stripe_payment_method_id: paymentMethodId,
      })

      if (response?.statusCode != 200) {
        if (response?.body && response.body?.["message"]) {
          throw new Error(
            response.body["message"] != ""
              ? response.body["message"]
              : "We were unable to process your request. Please try again later.",
          )
        } else {
          // throw a more generic error
          throw new Error(
            "We were unable to process your request. Please try again later.",
          )
        }
      }

      return response?.body ? response.body : null
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["current-orders"],
      })
    },
    onSettled: (_, error) => {
      if (!error) {
        queryClient.resetQueries({
          queryKey: [QUERY_KEYS.GET_CART],
        })
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_ORDERS],
        })
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_WALLETS],
        })
        queryClient.resetQueries({
          queryKey: [QUERY_KEYS.GET_CREDIT],
        })
        queryClient.resetQueries({
          queryKey: [QUERY_KEYS.GET_MERCHANT_LOYALTY_PROGRAM],
        })
      } else {
        // error refresh cart
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_CART],
        })
      }
    },
  })

  const setSchedule = useMutation<
    unknown,
    Error,
    {
      date: string
      startTime: string
      endTime: string
      scheduleType: OrderScheduleType
    },
    unknown
  >({
    mutationFn: async ({ date, startTime, endTime, scheduleType }) => {
      await apiClient.post(
        `stores/${cart?.store.id}/cart/edit-schedule-type/`,
        {
          type: scheduleTypeToString(scheduleType),
          window: {
            date,
            start_time: startTime,
            end_time: endTime,
          },
        },
      )
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GET_CART] })
    },
  })

  const applyPromoCode = useMutation<
    unknown,
    Error,
    {
      promoCode: string
    },
    unknown
  >({
    mutationFn: async ({ promoCode }) => {
      const res = await apiClient.post(
        `stores/${activeStore?.id}/cart/apply-promo-code/`,
        {
          promoCode,
        },
      )
      if (res?.statusCode !== 200) throw new Error()
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GET_CART] })
    },
  })

  const removePromo = useMutation<
    unknown,
    Error,
    {
      promoId: number
    },
    unknown
  >({
    mutationFn: async ({ promoId }) => {
      await apiClient.post(`stores/${activeStore?.id}/cart/remove-promo/`, {
        promoId,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GET_CART] })
    },
  })

  const setApplyCredits = useMutation<unknown, Error, boolean, unknown>({
    mutationFn: async (applyCredits: boolean) => {
      await apiClient.post(
        `stores/${activeStore?.id}/cart/edit-apply-credits/`,
        {
          apply_credits: applyCredits,
        },
      )
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GET_CART] })
    },
  })

  return {
    cart: order,
    setQuantity,
    setFulfillmentType,
    setStoreTip,
    setCourierTip,
    setSchedule,
    submitOrder,
    applyPromoCode,
    removePromo,
    setApplyCredits,
    updatePackagingPreferences,
  }
}
