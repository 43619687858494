import dayjs, { Dayjs } from "@helpers/dayjs";

export class OrderPickupDetail {
  constructor(
    public id: number,
    public orderId: number,
    public firstName: string,
    public lastName: string,
    public phoneNumber: string,
    public pickupReadyETA: Dayjs | null = null
  ) {}

  static fromDynamic(data: { [key: string]: any }): OrderPickupDetail {
    return new OrderPickupDetail(
      data["id"],
      data["order"],
      data["pickup_first_name"],
      data["pickup_last_name"],
      data["pickup_phone"],
      data["pickup_ready_eta"] == null ? null : dayjs(data["pickup_ready_eta"])
    );
  }
}
