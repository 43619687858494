import { forEach } from "lodash"
import { MenuItem } from "./menuItem"

export class MenuCategory {
  constructor(
    public id: number,
    public name: string,
    public description: string | null,
    public sortOrder: number,
    public menuItems: Array<MenuItem>,
  ) {}

  static fromDynamic(data: { [key: string]: any }) {
    // parse menu items for this category, separate by availablility
    const availableItems: Array<MenuItem> = []
    const unavailableItems: Array<MenuItem> = []

    forEach(
      data["menu_items"] ?? [],
      (menuItemJson: { [key: string]: any }) => {
        const parsedMenuItem = MenuItem.fromDynamic(menuItemJson)

        if (!parsedMenuItem?.isAvailable && parsedMenuItem?.isHidden) {
          return
        }

        if (parsedMenuItem?.isAvailable) {
          availableItems.push(parsedMenuItem)
        } else {
          unavailableItems.push(parsedMenuItem)
        }
      },
    )

    // sort the items by availability
    availableItems.sort((a, b) => a.sortOrder - b.sortOrder)
    unavailableItems.sort((a, b) => a.sortOrder - b.sortOrder)

    const name = `${data["name"]}`.endsWith(".")
      ? `${data["name"]}`.slice(0, `${data["name"]}`.length - 1)
      : `${data["name"]}`

    return new MenuCategory(
      data["id"],
      name,
      data["description"],
      data["sort_order"],
      availableItems.concat(unavailableItems), // show unavailable last
    )
  }
}
