export enum CardBrand {
  visa = "visa",
  mastercard = "mastercard",
  amex = "amex",
  discover = "discover",
  googlePay = "googlePay",
  applePay = "applePay",
  unknown = "unknown",
}

export class PaymentMethod {
  constructor(
    public id: number,
    public isDefault: boolean,
    public type: string,
    public last4: string,
    public brand: CardBrand,
    public expMonth: number,
    public expYear: number,
  ) {}

  static fromDynamic(data: { [key: string]: any }): PaymentMethod {
    return new PaymentMethod(
      data["id"],
      data["is_default"] ?? false,
      data["type"],
      data["card"]["last4"],
      data["card"]["brand"],
      data["card"]["exp_month"],
      data["card"]["exp_year"],
    )
  }
}
