/* 0 is Monday,6 is Sunday */
export enum DaysOfTheWeek {
  Monday = 0,
  Tuesday = 1,
  Wednesday = 2,
  Thursday = 3,
  Friday = 4,
  Saturday = 5,
  Sunday = 6
}

export class MenuHours {
  constructor(
    public dayOffset: DaysOfTheWeek,
    public openTimeHour: number,
    public openTimeMinute: number,
    public closeTimeHour: number,
    public closeTimeMinute: number
  ) {}

  static fromDynamic(data: { [key: string]: any }): MenuHours {
    return new MenuHours(
      data["day_offset"],
      data["open_time"]["hour"],
      data["open_time"]["minute"],
      data["close_time"]["hour"],
      data["close_time"]["minute"]
    );
  }
}
