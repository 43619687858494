import { ReactNode, useCallback, useEffect, useMemo, useState } from "react"
import styled, { css, keyframes } from "styled-components"

interface TransitionParams {
  length?: number
  delay?: number
  onComplete?: () => void
}

const defaultParams: TransitionParams = {
  length: 250,
  delay: 0,
}

type TransitionState = "entering" | "entered" | "exiting" | "exited"

interface TransitionController {
  state: TransitionState
  enter: (params?: TransitionParams) => void
  exit: (params?: TransitionParams) => void
  length: number
}

export const useTransition = (enterOnMount = true): TransitionController => {
  const [lastLength, setLastLength] = useState<number>(0)
  const [state, setState] = useState<TransitionState>("exited")
  const enter = useCallback(
    (params?: TransitionParams) => {
      if (state !== "entered" && state !== "entering") {
        const fullParams = { ...defaultParams, ...params }
        setLastLength(fullParams.length!)
        setTimeout(() => {
          setState("entering")
          setTimeout(() => {
            setState("entered")
            fullParams.onComplete?.()
          }, fullParams?.length)
        }, fullParams.delay)
      }
    },
    [state],
  )
  const exit = useCallback(
    (params?: TransitionParams) => {
      if (state !== "exited" && state !== "exiting") {
        const fullParams = { ...defaultParams, ...params }
        setLastLength(fullParams.length!)
        setTimeout(() => {
          setState("exiting")
          setTimeout(() => {
            setState("exited")
            fullParams.onComplete?.()
          }, fullParams?.length)
        }, fullParams.delay)
      }
    },
    [state],
  )
  useEffect(() => {
    if (enterOnMount) enter()
  }, [enterOnMount])
  const controller = useMemo(
    () => ({ state, enter, exit, length: lastLength }),
    [state, enter, exit, lastLength],
  )
  return controller
}

interface Props {
  controller: TransitionController
  children: ReactNode | ReactNode[]
}

export const Transition = ({ controller, children }: Props) => {
  if (controller.state === "exited") return null
  return (
    <Container $state={controller.state} $length={controller.length}>
      {children}
    </Container>
  )
}

const fade = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`

const Container = styled.div<{ $state: TransitionState; $length: number }>`
  ${({ $length }) => `
    animation-duration: ${$length}ms;
  `}
  ${({ $state }) =>
    $state === "entering"
      ? css`
          animation-name: ${fade};
          animation-fill-mode: forwards;
          animation-direction: normal;
        `
      : ``}
  ${({ $state }) =>
    $state === "exiting"
      ? css`
          animation-name: ${fade};
          animation-fill-mode: backwards;
          animation-direction: reverse;
        `
      : ``}
`
