import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useContext } from "react"
import { get } from "lodash"
import apiClient from "@api/client"
import { QUERY_KEYS } from "@api/constants"
import { MerchantConfigContext } from "providers/MerchantConfigProvider"
import { getIdentityQueryConfig } from "providers/IdentityProvider"

export const useUpdateCustomerActiveStore = (
  handleSetNewStore: (newStoreId: number | null) => void,
) => {
  const queryClient = useQueryClient()
  const { config } = useContext(MerchantConfigContext)

  return useMutation({
    mutationFn: async (storeId: number) => {
      // Change store location locally if the user is not logged in
      if (!apiClient.hasToken) {
        return true
      }

      const [updateCustomerStoreResponse] = await Promise.allSettled([
        apiClient.post("user/update-customer-store/", {
          storeId,
        }),
        apiClient.put(`stores/${storeId}/cart/edit-delivery-type/`, {
          fulfillmentType: "PICKUP",
          is_curbside: false,
        }),
      ])

      if (
        updateCustomerStoreResponse.status === "fulfilled" &&
        updateCustomerStoreResponse.value?.statusCode === 200
      ) {
        return true
      }

      throw new Error("Failed to update store id")
    },
    onSuccess: (data, newStoreId) => {
      handleSetNewStore(newStoreId)

      if (apiClient.hasToken) {
        queryClient.setQueryData(
          getIdentityQueryConfig(config?.merchantId).queryKey,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (oldData: any) => ({
            ...oldData,
            customer: {
              ...get(oldData, "customer"),
              last_selected_store_id: newStoreId,
            },
          }),
        )

        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_LOGIN],
        })
      }
    },
  })
}

export const useUpdateCustomerMarketingPreference = ({
  storeId,
  smsOptIn = false,
  emailOptIn = false,
}: {
  storeId: number
  smsOptIn?: boolean
  emailOptIn?: boolean
}) => {
  return useMutation({
    mutationFn: async () => {
      const response = await apiClient.post(
        "user/update-customer-marketing-preference/",
        { storeId, smsOptIn, emailOptIn },
      )

      return response?.statusCode === 200
    },
  })
}
