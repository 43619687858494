import styled from "styled-components"
import { IMaskInput } from "react-imask"
import { text } from "../theme"

interface Props {
  placeholder?: string
  value: string
  onChange: (value: string) => void
  onBlur?: () => void
  onSubmit?: () => void
  autoFocus?: boolean
  mask?: string
  disabled?: boolean
}

export const InputText = ({
  placeholder,
  value,
  onChange,
  onBlur,
  onSubmit,
  autoFocus,
  mask,
  disabled,
}: Props) => {
  // If a mask is provided for formatting phone numbers, currencies, etc, use a masked input with styles
  // provided by a parent div.
  if (mask)
    return (
      <Container>
        <IMaskInput
          mask={mask}
          unmask={true}
          value={value}
          placeholder={placeholder}
          onAccept={onChange}
          onBlur={onBlur}
          onSubmit={onSubmit}
          autoFocus={autoFocus}
          disabled={disabled}
        />
      </Container>
    )
  // Otherwise, just use a regular input with styling directly applied.
  return (
    <Input
      value={value}
      placeholder={placeholder}
      onChange={(e) => onChange(e.target.value)}
      onBlur={onBlur}
      onSubmit={onSubmit}
      autoFocus={autoFocus}
      disabled={disabled}
    />
  )
}

const Container = styled.div`
  width: 100%;
  input {
    border: solid 1px ${({ theme }) => theme.colors["Border/Primary"]};
    border-radius: ${({ theme }) => theme.borderRadius};
    padding: 12px;
    width: 100%;
    background-color: transparent;
    ${text("Body/Regular")}
  }
`

const Input = styled.input`
  border: solid 1px ${({ theme }) => theme.colors["Border/Primary"]};
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: 12px;
  width: 100%;
  background-color: transparent;
  ${text("Body/Regular")}
`
