import React, { ReactNode } from "react"
import styled, { css } from "styled-components"
import { ModalController } from "./interface"
import { BREAKPOINT_QUERY } from "openui/media"
import { Row } from "../Row"
import { Divider } from "../Divider"
import { Text } from "../Text"
import { Spacer } from "../Spacer"

interface Props {
  children: ReactNode | Array<ReactNode>
  controller: ModalController
  heading?: ReactNode
  left?: ReactNode
  right?: ReactNode
  footer?: ReactNode
  padding?: string
}

const intercept = (e: React.MouseEvent) => {
  e.stopPropagation()
}

export const Modal = ({
  children,
  controller,
  heading,
  left,
  right,
  footer,
  padding = "16px",
}: Props) => {
  if (!controller.isOpen) return null
  return (
    <Container onClick={controller.implicitClose}>
      <Content onClick={intercept}>
        {(!!left || !!heading || !!right) && (
          <Header>
            <Row justifyContent="space-between">
              <ZeroBasis>{left}</ZeroBasis>
              <ZeroBasis>
                {typeof heading === "string" ? (
                  <Text style="Body/Bold" whitespace="nowrap">
                    {heading}
                  </Text>
                ) : (
                  heading
                )}
              </ZeroBasis>
              <ZeroBasis>{right}</ZeroBasis>
            </Row>
          </Header>
        )}
        <Divider />
        <Scrollable $padding={padding}>{children}</Scrollable>
        {footer && <Footer>{footer}</Footer>}
      </Content>
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1000;
  display: flex;
  flex-direction: column;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 440px;
  height: fit-content;
  max-height: 80vh;
  border-radius: 10px;
  overflow: hidden;
  ${BREAKPOINT_QUERY["mobile"](`
      width: 100%;
      height: 100%;
      height: 100dvh;
      max-height: 100vh;
  `)}
  ${({ theme }) => css`
    background: ${theme.colors["Background/Primary"]};
    border: solid 1px ${({ theme }) => theme.colors["Border/Primary"]};
  `}
`

const Header = styled.div`
  padding: 16px;
`

const ZeroBasis = styled.div`
  flex: 0;
`

const Scrollable = styled.div<{ $padding: string }>`
  overflow: auto;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: ${({ $padding }) => $padding};
`

const Footer = styled.div`
  border-top: solid 1px ${({ theme }) => theme.colors["Border/Primary"]};
`
