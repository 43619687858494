import { createGlobalStyle } from "styled-components"
import localFont from "next/font/local"

export * from "./text"
export * from "./theme"
export * from "./color"

export const TWKLausanne = localFont({
  src: [
    {
      path: "../fonts/TWKLausanne/TWKLausanne-300.woff",
      weight: "300",
    },
    {
      path: "../fonts/TWKLausanne/TWKLausanne-400.woff",
      weight: "400",
    },
    {
      path: "../fonts/TWKLausanne/TWKLausanne-500.woff",
      weight: "500",
    },
    {
      path: "../fonts/TWKLausanne/TWKLausanne-600.woff",
      weight: "600",
    },
    {
      path: "../fonts/TWKLausanne/TWKLausanne-700.woff",
      weight: "700",
    },
  ],
})

export const GlobalStyle = createGlobalStyle`
body {
  margin: 0;
}

* {
  box-sizing:border-box;
}
`
