import { compact } from "lodash";

export class StoreAddress {
  constructor(
    public id: number,
    public street1: string,
    public street2: string | null = null,
    public street3: string | null = null,
    public city: string,
    public state: string,
    public zipCode: string,
    public lat: number | null = null,
    public lng: number | null = null
  ) {}

  toString(streetOnly = false): string {
    return compact([
      this.street1,
      this.street2,
      this.street3,
      streetOnly ? null : this.city,
      streetOnly ? null : this.state,
      streetOnly ? null : this.zipCode
    ]).join(" ");
  }

  static fromDynamic(data: { [key: string]: any }) {
    return new StoreAddress(
      data["id"],
      data["street1"],
      data["street2"],
      data["street3"],
      data["city"],
      data["state"],
      data["zip_code"],
      data["latlng"]["lat"],
      data["latlng"]["lng"]
    );
  }
}
