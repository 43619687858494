export class User {
  constructor(
    public id: number,
    public firstName: string | null = null,
    public lastName: string | null = null,
    public phoneUnverified: string | null = null,
    public phone: string | null = null,
    public email: string | null = null
  ) {}

  static fromDynamic(data: { [key: string]: any }) {
    return new User(
      data["id"],
      data["first_name"],
      data["last_name"],
      data["phone_unverified"],
      data["phone"],
      data["email"]
    );
  }
}
