import { useEffect, useState } from "react"
import dayjs, { formatTime } from "@helpers/dayjs"
import { FulfillmentType, OrderScheduleType } from "@models/order"
import { useFetchScheduleWindow } from "@api/menu/menu"
import {
  Button,
  Icon,
  IconKey,
  Loading,
  Modal,
  ModalController,
  Row,
  Spacer,
  Text,
} from "@openui"
import { useActiveMenu } from "hooks/useActiveMenu"
import { useCart } from "hooks/useCart"

interface Props {
  controller: ModalController
}

export const ScheduleDeliveryModal = ({ controller }: Props) => {
  const { cart, setSchedule } = useCart()
  const { data: activeMenu } = useActiveMenu()
  const { data: scheduleWindows, isLoading } = useFetchScheduleWindow({
    storeId: cart.data?.store?.id ?? -1,
    storeMenuId: activeMenu?.id ?? -1,
    cartId: cart.data?.id ?? -1,
  })

  const initialDateIdx =
    scheduleWindows?.findIndex((window) =>
      window.date.isSame(cart.data?.scheduledDayjs ?? dayjs(), "day"),
    ) ?? 0
  const initialTimeIdx =
    scheduleWindows?.[initialDateIdx]?.windows.findIndex(
      (window) => window.startTime === cart.data?.scheduledStartTime,
    ) ?? 0

  const [selectedDateIndex, setSelectedDateIndex] = useState<number | null>(
    null,
  )
  const [selectedTimeIndex, setSelectedTimeIndex] = useState<number | null>(
    null,
  )

  // Set initial indexes when scheduleWindows loads
  useEffect(() => {
    if (scheduleWindows) {
      setSelectedDateIndex(initialDateIdx)
      setSelectedTimeIndex(initialTimeIdx)
    }
  }, [scheduleWindows])

  const handleSave = () => {
    if (selectedDateIndex === null || selectedTimeIndex === null) {
      window.alert("Please select a date and time")
      return
    }

    const date = scheduleWindows?.[selectedDateIndex]?.date.format("YYYY-MM-DD")
    const startTime =
      scheduleWindows?.[selectedDateIndex]?.windows[selectedTimeIndex]
        ?.startTime
    const endTime =
      scheduleWindows?.[selectedDateIndex]?.windows[selectedTimeIndex]?.endTime

    // Base check for valid schedule window
    if (!date || !startTime || !endTime) {
      window.alert("Schedule window is not valid")
      controller.close()
      return
    }

    setSchedule.mutate(
      {
        date: date,
        startTime: startTime,
        endTime: endTime,
        scheduleType: OrderScheduleType.fixedTime,
      },
      {
        onSuccess: () => {
          controller.close()
        },
        onError: (error) => {
          window.alert("Failed to update order schedule window")
          console.log(error)
        },
      },
    )
  }

  return (
    <Modal
      controller={controller}
      heading="Schedule Order"
      right={<Icon iconKey={IconKey.Close} onClick={controller.close} />}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <Row gap="6px">
          <div style={{ flex: 1 }}>
            <Text style="Body/SemiBold" color="Content/Secondary">
              Date
            </Text>
            <Spacer size={3} />
            <div
              style={{
                border: "1px solid #E5E5E5",
                borderRadius: "4px",
                maxHeight: "200px",
                overflowY: "auto",
              }}
            >
              {scheduleWindows?.map((window, index) => (
                <div
                  key={window.date.format("YYYY-MM-DD")}
                  style={{
                    padding: "12px",
                    cursor: "pointer",
                    borderBottom: "1px solid #E5E5E5",
                    backgroundColor:
                      selectedDateIndex === index ? "#EEEEEE" : "transparent",
                  }}
                  onClick={() => setSelectedDateIndex(index)}
                >
                  <Text style="Body/Regular">
                    {window.date.isSame(dayjs(), "day")
                      ? "Today"
                      : window.date.isSame(dayjs().add(1, "day"), "day")
                        ? "Tomorrow"
                        : window.date.format("ddd, MMM D")}
                  </Text>
                </div>
              ))}
            </div>
          </div>

          <div style={{ flex: 1 }}>
            <Text style="Body/SemiBold" color="Content/Secondary">
              Time
            </Text>
            <Spacer size={3} />
            <div
              style={{
                border: "1px solid #E5E5E5",
                borderRadius: "4px",
                maxHeight: "200px",
                overflowY: "auto",
              }}
            >
              {scheduleWindows?.[selectedDateIndex ?? 0]?.windows.map(
                (timeWindow, index) => (
                  <div
                    key={`${timeWindow.startTime}-${timeWindow.endTime}`}
                    style={{
                      padding: "12px",
                      cursor: "pointer",
                      borderBottom: "1px solid #E5E5E5",
                      backgroundColor:
                        selectedTimeIndex === index ? "#F5F5F5" : "transparent",
                    }}
                    onClick={() => setSelectedTimeIndex(index)}
                  >
                    <Text style="Body/Regular">
                      {timeWindow.isASAP
                        ? "ASAP"
                        : cart.data?.fulfillmentType == FulfillmentType.pickup
                          ? formatTime(timeWindow.startTime)
                          : `${formatTime(timeWindow.startTime)} - ${formatTime(timeWindow.endTime)}`}
                    </Text>
                  </div>
                ),
              )}
            </div>
          </div>
        </Row>
      )}
      <Spacer size={2} />
      <Button
        onClick={handleSave}
        width="100%"
        isLoading={setSchedule.isPending}
      >
        Save
      </Button>
    </Modal>
  )
}
