import { useCallback, useMemo, useState } from "react"
import { ModalController } from "./interface"

interface Params {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  allowImplicitClose?: boolean
  onClose?: () => void
}

export const useModal = (params?: Partial<Params>): ModalController => {
  const [internalIsOpen, internalSetIsOpen] = useState(false)
  const isOpen = params?.isOpen ?? internalIsOpen
  const setIsOpen = params?.setIsOpen ?? internalSetIsOpen

  const open = useCallback(() => {
    setIsOpen(true)
  }, [setIsOpen])

  const close = useCallback(() => {
    if (isOpen) {
      setIsOpen(false)
      params?.onClose?.()
    }
  }, [params, isOpen, setIsOpen])

  const implicitClose = useCallback(() => {
    if (params?.allowImplicitClose ?? true) {
      close()
    }
  }, [params?.allowImplicitClose, close])

  const toggle = useCallback(() => {
    if (isOpen) {
      close()
    } else {
      open()
    }
  }, [close, isOpen, open])

  const controller = useMemo(
    () => ({ isOpen, open, close, toggle, implicitClose }),
    [isOpen, open, close, toggle, implicitClose],
  )

  return controller
}
