import React, { ReactNode } from "react"
import styled, { css } from "styled-components"
import { Spacer } from "./Spacer"
import { Text } from "./Text"
import { Loading } from "./Loading"

interface Props {
  value: boolean
  isLoading?: boolean
  onChange: (value: boolean) => void
  label?: ReactNode
  centerLabel?: boolean
}

export const InputCheckbox = ({
  value,
  isLoading,
  onChange,
  label,
  centerLabel = true,
}: Props) => {
  return (
    <Container
      onClick={isLoading ? undefined : () => onChange(!value)}
      $center={centerLabel}
    >
      <Box active={!isLoading && value}>
        {isLoading ? <Loading /> : <Checkmark active={value} />}
      </Box>
      {label !== undefined && (
        <>
          <Spacer size={2} $vertical />
          {label}
        </>
      )}
    </Container>
  )
}

const Container = styled.div<{ $center: boolean }>`
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  ${({ $center }) => ($center ? "align-items: center;" : "")}
`

const Box = styled.div<{ active: boolean }>`
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  border: solid 1.5px ${({ theme }) => theme.colors["Content/Primary"]};
  border-radius: ${({ theme }) => theme.borderRadius};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s;
  ${({ active, theme }) =>
    active
      ? css`
          background-color: ${theme.colors["Content/Primary"]};
        `
      : ""}
`

const Checkmark = styled.div<{ active: boolean }>`
  width: 7px;
  height: 12px;
  transform: rotate(45deg) translate(-1px, -1px);
  transition: all 0.2s;
  ${({ active }) =>
    active
      ? css`
          border-bottom: solid 2px #ffffff;
          border-right: solid 2px #ffffff;
        `
      : ""}
`
