export const toHexColor = (hexString: string): string => {
  const hex = parseInt(hexString, 16)
  // Extract the last 24 bits using a bitwise AND with 0xFFFFFF
  const color = hex & 0xffffff

  // Convert the color to a hexadecimal string and pad with leading zeros if necessary
  const hexColor = color.toString(16).padStart(6, "0")

  // Return the color in #RRGGBB format
  return `#${hexColor.toUpperCase()}`
}
