import React, { useContext, useEffect } from "react"
import styled, { ThemeProvider } from "styled-components"
import * as Sentry from "@sentry/nextjs"
import { Reset } from "styled-reset"
import { AppProps } from "next/app"
import { get } from "lodash"
import { useRouter } from "next/navigation"
import Head from "next/head"
import { GlobalStyle } from "@openui"
import { Header } from "@components/Header"
import { MerchantConfigContext } from "providers/MerchantConfigProvider"
import { IdentityContext } from "providers/IdentityProvider"
import { useScreenSize } from "hooks/useScreenSize"
import { ENV } from "@constants/env"

Sentry.init({
  environment: ENV.ENV,
})

export const Main = ({ Component, pageProps }: AppProps) => {
  const router = useRouter()
  const identity = useContext(IdentityContext)
  const merchantConfig = useContext(MerchantConfigContext)
  const { isMobile } = useScreenSize()

  useEffect(() => {
    if (
      get(Component, "loggedInOnly") &&
      identity.isFetched &&
      !identity.identity
    ) {
      router.replace("/login")
    }
  }, [Component, identity.isFetched])

  return (
    <ThemeProvider theme={merchantConfig.theme}>
      {merchantConfig.config?.displayName ? (
        <Head>
          <title>{merchantConfig.config?.displayName}</title>
          <meta
            name="title"
            property="og:title"
            content={merchantConfig.config?.displayName}
          />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=1"
          />
          <meta
            name="Description"
            content={merchantConfig.config.displayName}
          ></meta>
          {merchantConfig.config.iconUrl ? (
            <>
              <meta
                property="og:image"
                content={merchantConfig.config.iconUrl}
              ></meta>
              <meta property="og:image:type" content="image/png"></meta>
              <meta property="og:image:width" content="128"></meta>
              <meta property="og:image:height" content="128"></meta>
              <meta
                property="og:description"
                content={merchantConfig.config.displayName}
              ></meta>
            </>
          ) : null}
        </Head>
      ) : null}
      <Reset />
      <Page>
        {get(Component, "hideHeader") ||
        (get(Component, "hideHeaderOnMobile") && isMobile) ? null : (
          <HeaderWrapper>
            <Header />
          </HeaderWrapper>
        )}
        <Body>
          {get(Component, "loggedInOnly") && !identity.identity ? null : (
            <Component {...pageProps} />
          )}
        </Body>
      </Page>
      <GlobalStyle />
    </ThemeProvider>
  )
}

const Page = styled.div`
  width: 100%;
  height: 100vh;
  /* Needed for safari and the floating action bar */
  height: 100dvh;
  display: flex;
  flex-direction: column;

  background: ${({ theme }) => theme.colors["Background/Primary"]};
  --iconColor: ${({ theme }) => theme.colors["Content/Primary"]};
`

const HeaderWrapper = styled.div`
  position: sticky;
  z-index: 100;
  top: 0;
`

const Body = styled.div`
  width: 100%;
  flex: 1;

  overflow: auto;
  scroll-behavior: smooth;
`
