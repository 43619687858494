import { useStripe, useElements } from "@stripe/react-stripe-js"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import apiClient from "@api/client"
import { QUERY_KEYS } from "@api/constants"

const ADD_CARD_ERROR = "Unable to add payment. Please try again later."

export const useOpenStripe = () => {
  const queryClient = useQueryClient()
  const stripe = useStripe()
  const elements = useElements()

  const { mutateAsync: getClientSecretAsync } = useMutation({
    mutationFn: async (): Promise<string> => {
      const response = await apiClient.get<{ client_secret: string }>(
        "user/payment-method/setup-intent",
      )

      if (response?.statusCode === 200 && response.body) {
        return response.body.client_secret
      }

      throw new Error("Failed to create setup intent")
    },
  })

  const { mutateAsync: syncCardAsync } = useMutation({
    mutationFn: async (setupIntent: string) => {
      const response = await apiClient.get<{ client_secret: string }>(
        `user/confirm-payment-method?setup_intent=${setupIntent}`,
      )

      return response?.statusCode === 200
    },
  })

  const addCardMutation = useMutation({
    mutationFn: async () => {
      if (!elements || !stripe) {
        console.error("Stripe is not initialized")
        throw new Error(ADD_CARD_ERROR)
      }

      const clientSecret = await getClientSecretAsync()
      const cardElement = elements.getElement("card")

      if (!cardElement) {
        console.error("Stripe Card element is not found")
        throw new Error(ADD_CARD_ERROR)
      }

      const setupIntent = await stripe?.confirmCardSetup(clientSecret, {
        payment_method: {
          card: cardElement,
        },
      })

      if (
        setupIntent.setupIntent?.status === "succeeded" &&
        !setupIntent.error
      ) {
        await syncCardAsync(setupIntent.setupIntent.id)
        await queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_WALLETS],
        })
      } else {
        console.error(setupIntent.error?.message)
        throw new Error(ADD_CARD_ERROR)
      }
    },
  })

  return {
    stripe,
    elements,
    addCardMutation,
  }
}
