export const formatPhoneNumber = (value: string): string => {
  // The shortest a phone number can be with a single-digit country code is 11.
  // If shorter, assume +1.
  if (value.length < 10) return value
  if (value.length === 10) value = `1${value}`
  const countryCode = value.slice(0, value.length - 10)
  const firstThree = value.slice(-10, value.length - 7)
  const secondThree = value.slice(-7, value.length - 4)
  const lastFour = value.slice(-4, value.length)
  return `+${countryCode} (${firstThree}) ${secondThree}-${lastFour}`
}
