import dayjs, { Dayjs } from "@helpers/dayjs";

export class OrderDeliveryDetail {
  constructor(
    public id: number | null = null,
    public status: string,
    public deliveryInstruction: DeliveryInstruction | null = null,
    public deliveryAddress: string | null = null,
    public deliveryAddress2: string | null = null,
    public addressLat: number | null = null,
    public addressLng: number | null = null,
    public city: string | null = null,
    public state: string | null = null,
    public postalCode: string | null = null,
    public courierName: string | null = null,
    public courierPhone: string | null = null,
    public courierLat: number | null = null,
    public courierLng: number | null = null,
    public estimatedPickupAt: Dayjs | null = null,
    public deliveryETA: Dayjs | null = null,
    public dropoffVerificationImageUrl: string | null = null,
    public orderId: number | null = null
  ) {}

  getDeliveryAddress(): string {
    const unitAptNumber = ` Unit/Apt: ${this.deliveryInstruction?.unitApt ?? ""}`;
    return `${this.deliveryAddress}${unitAptNumber}, ${this.city}, ${this.state} ${this.postalCode}`;
  }

  static fromDynamic(data: { [key: string]: any }) {
    return new OrderDeliveryDetail(
      data["id"],
      data["status"],
      DeliveryInstruction.fromDynamic(data["dropoff_instructions"]),
      data["delivery_address"]?.["street1"],
      data["delivery_address"]?.["street2"],
      data["delivery_address"]?.["latlng"]?.["lat"],
      data["delivery_address"]?.["latlng"]?.["lng"],
      data["delivery_address"]?.["city"],
      data["delivery_address"]?.["state"],
      data["delivery_address"]?.["zip_code"],
      data["courier_name"],
      data["courier_phone_for_dropoff_to_use"],
      data["courier_lat"],
      data["courier_lng"],
      data["estimated_pickup_at"] == null
        ? null
        : dayjs(data["estimated_pickup_at"]),
      data["estimated_dropoff_at"] == null
        ? null
        : dayjs(data["estimated_dropoff_at"]),
      data["dropoff_verification_image_url"],
      data["order"]
    );
  }
}

class DeliveryInstruction {
  constructor(
    public unitApt: string | null = null,
    public entryCode: string | null = null,
    public instructions: string | null = null
  ) {}

  static fromDynamic(data: { [key: string]: any }) {
    if (!data) return null;

    // Initialize variables
    let unitApt = null;
    let entryCode = null;
    let instructions = null;

    // Parse unit/apt
    const unitMatch = data.match(/Unit\/Apt:\s*([^.]+)/);
    if (unitMatch) {
      unitApt = unitMatch[1].trim();
    }

    // Parse entry code  
    const codeMatch = data.match(/Entry code:\s*([^.]+)/);
    if (codeMatch) {
      entryCode = codeMatch[1].trim();
    }

    // Parse instructions
    const instructionsMatch = data.match(/Instructions:\s*([^.]+)/);
    if (instructionsMatch) {
      instructions = instructionsMatch[1].trim();
    }

    return new DeliveryInstruction(unitApt, entryCode, instructions);
  }
}